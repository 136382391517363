import { capitalize, get } from 'lodash';
import rs from 'randomstring';
import * as htmlToImage from 'html-to-image';
import { IForm } from '../types';
import { nav } from '../data';

const formParamsMapping = {
  briete: 'number',
  lange: 'number',
  kopfteil: 'object|values:headboard,width',
  farbe: 'object|values:color,material',
  matratze: 'object|values:matratzeWeight1,matratzeWeight2,type',
  fube: 'object|values:category,type',
  topper: 'object|values:type,width',
  bedType: 'string',
  storageType: 'string',
  step: 'object|values:navStep,state,subNavStep|step_type',
  currentState: 'object|values:currentNavState,currentSubNavState|state_type'
} as Record<string, string>;

export const formatFabric = (farbe: { material: string; color: string }) => {
  let { material, color } = farbe;

  color = color.replace('_', ' ');

  if (material === 'fineWeave') {
    material = 'Feiner Stoff';
  } else if (material === 'roughWeave') {
    material = 'Grober Stoff';
  } else if (material === 'leather') {
    material = 'Leder';
  } else if (material === 'velvet') {
    material = 'Samt';
  } else {
    material = '';
  }

  return `${material} ${capitalize(color)}`;
};

export const formatLegAndHeadboard = (value: string): string => {
  return value.replace(/(headboard|leg)/g, '');
};

export const formatLegType = (value: string): string => {
  if (value.includes('wood')) return 'Holzoptik';
  if (value.includes('steel')) return 'Edelstahl';

  return value;
};

export const getLegType = (value: string): string => {
  if (value.includes('wood')) return 'wood';
  else if (value.includes('steel')) return 'steel';
  else return value;
};

const stringfyForm = (form: Record<string, any>, navs: Record<string, any>): string => {
  let formString = '';

  Object.keys(form).forEach((key, index) => {
    let value = form[key];

    if (index !== 0) formString += ',';

    if (typeof value === 'object' && value != null) {
      Object.keys(value).forEach((_key, _index) => {
        if (_index !== 0) formString += '|';
        formString += value[_key];
      });
    } else {
      formString += form[key];
    }
  });

  // Append navs
  Object.keys(navs).forEach((key, index) => {
    let value = navs[key];

    formString += ',';

    if (typeof value === 'object' && value != null) {
      Object.keys(value).forEach((_key, _index) => {
        if (_index !== 0) formString += '|';
        formString += value[_key];
      });
    } else {
      formString += navs[key];
    }
  });

  return formString;
};

export const updateUrlParams = (form: IForm, navs: Record<string, any>) => {
  //Update url
  let formString = stringfyForm(form, navs);

  //Convert to base64
  let stringBase64 = convertToBase64(formString);

  const url = new URL(window.location.href);

  url.searchParams.set('state', stringBase64);

  window.history.pushState({}, '', url.toString());
};

export const decodeFormString = (state: string) => {
  let form = {},
    step = {
      navStep: 0,
      state: nav[0],
      subNavStep: 0
    },
    currentState = {
      currentNavState: 0,
      currentSubNavState: 0
    };
  let decodedString = decodeBase64(state);

  const valueArray = decodedString.split(',');

  Object.keys(formParamsMapping).forEach((key, index) => {
    //spilt array

    //Handle state

    let _array = formParamsMapping[key].split('|');

    if (_array.includes('object')) {
      const options = _array[1].slice(7).split(',');
      //split option values
      const optionValues = valueArray[index].split('|');

      if (options.length === optionValues.length) {
        let obj = {};
        options.forEach((_key, index) => {
          obj = {
            ...obj,
            [_key]: optionValues[index] === 'null' ? null : optionValues[index]
          };
        });
        if (_array.includes('step_type')) {
          step = { ...step, ...obj };
        } else if (_array.includes('state_type')) {
          currentState = { ...currentState, ...obj };
        } else {
          form = { ...form, [key]: obj };
        }
      }
    } else {
      let val =
        valueArray[index] === 'null'
          ? null
          : _array.includes('number')
          ? +valueArray[index]
          : valueArray[index];
      form = { ...form, [key]: val };
    }
  });

  return { form, step, currentState };
};

// NOTE : The function is not generic , it is tailored to the way the form is structured
export const calTotal = (form: any, prices: any) => {
  let total = 0;
  let width = form['briete'];

  Object.keys(form).forEach((key) => {
    if (form[key]) {
      if (typeof form[key] === 'object') {
        Object.keys(form[key]).forEach((objectKey) => {
          if (form[key][objectKey]) {
            if (key === 'farbe' && objectKey === 'material') {
              total += getPrice(prices, width, key, form[key][objectKey]);
            }

            if (key === 'kopfteil') {
              total += getPrice(prices, width, key, form[key][objectKey]);
            }

            if (key === 'fube' && objectKey === 'type') {
              if (form[key][objectKey].includes('wood')) {
                total += getPrice(prices, width, key, 'wood');
              } else if (form[key][objectKey] === 'steel') {
                total += getPrice(prices, width, key, 'steel');
              } else if (form[key]['category'] === 'leg4') {
                total += getPrice(prices, width, key, 'floating');
              }
            }
            if (key === 'topper' && objectKey === 'width') {
              total += getPrice(prices, width, key, form[key][objectKey]);
            }
          }
        });
      } else {
        if (key === 'briete') {
          total += getPrice(prices, width, 'base_price', form[key]);
        }
        if (key === 'lange') {
          total += getPrice(prices, width, key, form[key]);
        }

        if (key === 'bedType') {
          if (form[key] === 'storage') {
            if (form['storageType'] === 'front') {
              total += getPrice(prices, width, 'upgrades', 'front');
            }
            if (form['storageType'] === 'side') {
              total += getPrice(prices, width, 'upgrades', 'side');
            }
          }

          if (form[key] === 'electric') {
            total += getPrice(prices, width, 'upgrades', 'electric');
          }
        }
      }
    }
  });

  return total;
};

export const formatCurrency = (value: number, currency: string = 'EUR') => {
  if (!value) return '-';
  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency
  });

  value = value / 100;

  return formatter.format(parseFloat(`${value.toString()}.00`));
};

export const handleView = (id: string) => {
  const Div = document.getElementById(id) as any;

  const classes = [...Div.classList].toString();

  const isOpen = classes.includes('slideIn');

  if (isOpen) {
    Div.classList.remove('slideIn');
    Div.classList.add('slideOut');
  } else {
    Div.classList.remove('slideOut');
    Div.classList.add('slideIn');
  }
};

export const getPrice = (
  prices: any,
  width: string,
  category: string,
  optionValue: string
): number => {
  const options = get(prices, `${width}.${category}`, []);

  const value = options.find((option: any) => option.option.toString() === optionValue.toString());

  if (!value) {
    return 0;
  }

  return value.amount;
};

export const generateRandomString = (length = 6, prefixCount = 3) => {
  let prefix: string = rs.generate({
    charset: 'alphabetic',
    length: prefixCount
  });

  let suffix = rs.generate({
    charset: 'numeric',
    length
  });
  return prefix.concat(suffix);
};

export const getItemLocalStorage = (item: string) => {
  try {
    return JSON.parse(localStorage.getItem(item) || '');
  } catch {
    return null;
  }
};

export const convertDivToImg = async (ref: any, bedClone: any, imageType = 'image/png') => {
  try {
    //Clone node
    const clone = ref.cloneNode(true);

    //refecth image
    const imgs = Array.from(clone.getElementsByTagName('img')) as any[];

    for (let img of imgs) {
      if (img && img.src) {
        let src = img.src;

        src = src.replace('http://', 'https://');

        const response = await fetch(`${src}?creatingBedImage=true`);

        img.src = response.url;
        img.classList.add('bed-img');
      }
    }

    bedClone.appendChild(clone);

    const bedSection = bedClone && (bedClone.querySelector('#bedSection') as any);

    bedSection.classList.add('bed-container');

    //Hacky workaround to be clean up with Javascript implementation

    await htmlToImage.toPng(bedSection);

    await htmlToImage.toPng(bedSection);

    await htmlToImage.toPng(bedSection);

    await htmlToImage.toPng(bedSection);

    const dataURL = await htmlToImage.toPng(bedSection);

    const blob = await (await fetch(dataURL)).blob();

    const fileName = generateRandomString();

    const file = new File([blob], `${fileName}.png`, { type: imageType });

    return { file, dataURL };
  } catch (e) {
    console.error('Error in helper' + e);

    return { file: '', dataURL: '' };
    //Handle error
  }
};

export const readURL = (file: any) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(reader.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export function convertToBase64(text: string) {
  return Buffer.from(text).toString('base64');
}

export function decodeBase64(text: string) {
  return Buffer.from(text, 'base64').toString();
}

// const fetchImages = async (imgs: string[]) => {
//   // setLoading(true);
//   try {
//     for (let i = 0; i < imgs.length; i++) {
//       let img = imgs[i];

//       if (img && img.includes('res.cloudinary.com')) {
//         //fetch image
//         img.replace('http://', 'https://');

//         const response = await fetch(img);

//         // const imgRef = bedRef.querySelector(`position${i + 1}`);

//         // console.log({imgRef})

//         // imgRef.src = response.url;
//       }
//     }
//   } catch (e) {
//   } finally {
//     // setLoading(false);
//   }
// };
