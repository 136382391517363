import { useCallback, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../context';
import axios from 'axios';
import { decodeFormString } from '../util/helper';
import { findBedImages } from '../util/bedconfig';

export const useAppConfigLoad = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryRedirect = urlParams.get('redirect');
  const state = urlParams.get('state');
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingProperties, setLoadingProperties] = useState(false);

  const {
    step,
    properties,
    setProperties,
    form,
    resetForm,
    defaultValues,
    setForm,
    setStep,
    setCurrentState
  } = useContext(ConfigContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [step]);

  const loadDefaultImages = useCallback((timer: any, configurations: any) => {
    const images: string[] = [
      process.env.REACT_APP_URL + '/images/backgroundImages/90cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/1000cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/1200cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/1400cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/1600cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/1800cm.png',
      process.env.REACT_APP_URL + '/images/backgroundImages/2000cm.png'
    ];

    [180, 90, 100, 120, 140, 160, 200].forEach((value) => {
      const positions = findBedImages(defaultValues, configurations[value]);

      Object.values(positions).forEach((image) => {
        images.push(image);
      });
    });

    let url = new URL(`../image-loader.worker.js`, import.meta.url);

    const ImageLoaderWorker = new window.Worker(url);
    // Post images
    ImageLoaderWorker.postMessage({
      isAppLoad: true,
      images
    });

    timer = setTimeout(() => {
      setLoadingImages(false);
    }, 5000);
  }, []);

  useEffect(() => {
    let timer: any;
    (async function () {
      const baseURL = process.env.REACT_APP_BASE_URL;
      setLoadingProperties(true);
      try {
        const { data } = await axios.get(`${baseURL}/config/properties/all`);
        setProperties({ ...data });
        loadDefaultImages(timer, data.configurations);
      } catch (e) {
        console.error('Fetching properties error', e);
      } finally {
        setLoadingProperties(false);
      }
    })();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [setProperties, loadDefaultImages]);

  //Decode string
  useEffect(() => {
    if (!!state) {
      const { form, step, currentState } = decodeFormString(state || '');

      setForm({ ...form });

      setStep({ navStep: +step.navStep, state: step.state, subNavStep: +step.subNavStep });

      setCurrentState({
        currentNavState: +currentState.currentNavState,
        currentSubNavState: +currentState.currentSubNavState
      });
    }
  }, []);

  useEffect(() => {
    if (queryRedirect === 'true') {
      resetForm();
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [queryRedirect, resetForm]);

  // Load worker
  useEffect(() => {
    if (form.briete) {
      let url = new URL(`../image-loader.worker.js`, import.meta.url);
      url.searchParams.set('briete', form.briete);
      const ImageLoaderWorker = new window.Worker(url);

      if (properties.configurations) {
        ImageLoaderWorker.postMessage({
          briete: form.briete,
          properties: properties.configurations[form.briete],
          isAppLoad: false
        });
      }
    }
  }, [properties.configurations, form.briete]);

  return { loadingImages, loadingProperties };
};
