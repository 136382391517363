import classNames from 'classnames';
import { useContext, useState } from 'react';
import { ConfigContext } from '../../../../context';
import { nav } from '../../../../data';
import { validateField } from '../../../../util/validation';
import Direction from '../../../Directions';
import InputButton from '../../../InputButton/InputButton';
import styles from './Fube.module.scss';
import { IForm } from '../../../../types';

function Fube({ data }: any) {
  const { form, setForm, fields, setErrors, setStep, setIsImageLoaded3, errors } =
    useContext(ConfigContext);

  const category = form?.fube?.category;

  const getFubeOptions = (category: string) => {
    const { hohe } = data;
    const fube = hohe.find((fube: any) => fube.value === category);
    return fube ? fube.options : [];
  };

  const [fubeOptions, setFubeOptions] = useState<any[]>(category ? getFubeOptions(category) : []);

  const handleChange = (e: any) => {
    setIsImageLoaded3(true);
    const { value, name } = e.target;
    let fube: any = null;

    if (name === 'category') {
      const options: any[] = getFubeOptions(value);

      if (options.length === 1) {
        fube = options[0].value;
      }
      setFubeOptions([...options]);
    }

    setForm((prevForm: IForm) => ({
      ...prevForm,
      bedType:
        name === 'category' && prevForm.bedType === 'electric' && value === 'leg4'
          ? 'standard'
          : prevForm.bedType,
      fube: {
        category: name === 'category' ? value : prevForm.fube.category,
        type: name === 'category' ? fube : value
      }
    }));
  };

  const handleNext = () => {
    const errors = validateField(form, fields['fube']);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        navStep: step.navStep + 1,
        state: nav[step.navStep + 1],
        subNavStep: step.subNavStep + 1
      }));
    }
  };

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      subNavStep: step.subNavStep - 1
    }));
  };

  return (
    <section className={styles.section} id="aside">
      <div className={styles.header}>
        <h2>Füße</h2>
        <p>Suche die passenden Füße zu deinem Stil.</p>
      </div>

      <div className={styles.main}>
        <span className={classNames({ error: errors['fube.category'] })}>10cm (Höhe)</span>
        <div className={styles.button}>
          {data.hohe?.map((item: any, index: any) => (
            <div
              className={classNames(styles.mainTitle, {
                'low-opacity': item.value !== form.fube.category && form.fube.category !== null
              })}
              key={index}>
              <InputButton
                item={item.value}
                image
                name="category"
                checked={item.value === form.fube.category}
                handleChange={handleChange}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        {/* <span>Schwebeoptik . Nur hintere Fube sinctbar</span> */}
        <div className={styles.legOptions}>
          {fubeOptions.map((item: any, index: any) => (
            <div key={index}>
              <p>{item.name}</p>
              <InputButton
                item={item.value}
                image
                name="type"
                circular
                checked={item.value === form.fube.type}
                handleChange={handleChange}>
                <div
                  className="input_options"
                  style={{
                    backgroundColor: item.backgroundColor
                  }}
                />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      {form.fube.category && (
        <Direction
          backButton={{
            label: '',
            handleBack,
            imgStyles: {
              marginLeft: '0'
            }
          }}
          nextButton={{ label: 'weiter', handleNext }}
        />
      )}
    </section>
  );
}

export default Fube;
