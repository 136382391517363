export const validateField = (form: any, fields: string[]) => {
  const errors = {} as any;

  fields.forEach((field) => {
    const splittedFields = field.split('.');

    const value = splittedFields.reduce((previousVal: any, field: any) => {
      previousVal = previousVal[field];

      return previousVal;
    }, form);

    if (!Boolean(value)) {
      errors[field] = true;
    }
  });

  return errors;
};
