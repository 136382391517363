import { useContext } from "react";
import { handleView } from "../../util/helper";
import styles from "./Content.module.scss";
import Button from "../Button/Button";
import { ConfigContext } from "../../context";

export default function Restart({ children }: any){
  const { resetForm, setStep } = useContext(ConfigContext);

  return (
    <div className={styles.infoSlide} id="restart">
      {children}

      <div className={styles.ibm_errechne}>
        <h4>Neustart</h4>
        <p className="text--sm">
          Möchtest du von vorne starten und deine aktuelle aktuelle
          Konfiguration abbrechen?
        </p>

        <div className={styles.restart_btns}>
          <Button
            label="Ja"
            onClick={() => {
              resetForm();
              setStep((step: any) => ({
                ...step,
                navStep: 0,
              }));
              handleView("restart");
            }}
            transparent
            customStyles={{
              borderRadius: "3px",
              padding: "1px 16px",
              marginRight: "10px",
              fontSize: "1.1rem"

            }}
          />

          <Button
            label="Nein"
            onClick={() => {
                handleView("restart");
            }}
            transparent
            customStyles={{
              borderRadius: "3px",
              padding: "1px 16px",
              fontSize: "1.1rem"
            }}
          />
        </div>
      </div>
    </div>
  );
};
