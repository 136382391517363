import { useContext } from 'react';
import { convertDivToImg } from '../util/helper';
import axios, { AxiosError } from 'axios';
import { ConfigContext } from '../context';
import LogRocket from 'logrocket';
import toast from 'react-hot-toast';

export const useCheckout = () => {
  const { amount, stoffmusterAmount, form, stoffmusters, setPageLoader } =
    useContext(ConfigContext);
  return {
    checkout: async function (bedRef: any, bedCloneRef: any, isStoffmuster: boolean) {
      setPageLoader(true);
      try {
        //@ts-ignore
        if (!origin.includes('localhost')) {
          LogRocket.captureMessage('Processing checkout');
        }

        const baseURL = process.env.REACT_APP_BASE_URL;

        let image = '';

        if (!isStoffmuster) {
          const { file } = await convertDivToImg(bedRef.current, bedCloneRef.current);

          const formData = new FormData();

          formData.append('file', file);

          formData.append('bed', form);

          //upload

          const { data } = await axios.post(`${baseURL}/upload`, formData);

          image = data.file;

          if (!origin.includes('localhost')) {
            LogRocket.captureMessage('File processed compeletly');
          }
        }

        const {
          data: { link }
        } = await axios.post(`${baseURL}/config/session`, {
          amount: isStoffmuster ? stoffmusterAmount : amount,
          image,
          stoffmusters: isStoffmuster ? stoffmusters : [],
          bed: form
        });

        if (!origin.includes('localhost')) {
          LogRocket.captureMessage('Link processed compeletly');
        }

        window.location.href = link;
      } catch (e) {
        toast.error('An error occured while processing your request');
        LogRocket.captureException(e as AxiosError);
        console.error('Error creating image and checking out' + JSON.stringify(e));
      } finally {
        setPageLoader(false);
      }
    }
  };
};
