import { capitalize } from 'lodash';
import { useContext } from 'react';
import { ConfigContext } from '../../../context';
import {
  formatCurrency,
  formatFabric,
  formatLegAndHeadboard,
  getPrice,
  formatLegType
} from '../../../util/helper';
import styles from './DeinBett.module.scss';
import Direction from '../../Directions';
import { nav } from '../../../data';

function DeinBett({ onCheckOut }: any) {
  const { properties, amount, form, setStep } = useContext(ConfigContext);

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      navStep: step.navStep - 1,
      state: nav[step.navStep - 1]
    }));
  };

  return (
    <div>
      <section className={styles.section}>
        <div className={styles.header}>
          <h2>Dein mollybett</h2>
        </div>

        <div className={styles.main}>
          <div className={styles.subHeader}>
            <p>Zusammenfassung</p>
            <p>{formatCurrency(amount)}</p>
          </div>

          <div>
            <div className={styles.priceList}>
              <h6>Briete : {form.briete}cm</h6>
              <h6>
                {formatCurrency(
                  getPrice(properties.prices, form.briete, 'base_price', form.briete)
                )}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>Länge : {form.lange}cm</h6>
              <h6>
                {formatCurrency(getPrice(properties.prices, form.briete, 'lange', form.lange))}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>Kopfteil : No. {formatLegAndHeadboard(form.kopfteil.headboard)}</h6>
              <h6>
                {formatCurrency(
                  getPrice(properties.prices, form.briete, 'kopfteil', form.kopfteil.width)
                )}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>Farbe : {formatFabric(form.farbe)}</h6>
              <h6>
                {formatCurrency(
                  getPrice(properties.prices, form.briete, 'farbe', form.farbe.material)
                )}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>
                Füße : No. {formatLegAndHeadboard(form.fube.category)},{' '}
                {capitalize(formatLegType(form.fube.type))}
              </h6>
              <h6>
                {formatCurrency(getPrice(properties.prices, form.briete, 'fube', form.fube.type))}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>
                Matratzenhärte{' '}
                {`${form.matratze.matratzeWeight1} ${
                  form.matratze.matratzeWeight2 ? `& ${form.matratze.matratzeWeight2}` : ''
                }`}
              </h6>
              <h6>-</h6>
            </div>
            <div className={styles.priceList}>
              <h6>
                Matratzenoptik : {capitalize(form.matratze.type === 'full' ? 'Getrennt' : 'Stück')}
              </h6>
              <h6>-</h6>
            </div>
            <div className={styles.priceList}>
              <h6>Topper : {form.topper.width}</h6>
              <h6>
                {formatCurrency(
                  getPrice(properties.prices, form.briete, 'topper', form.topper.width)
                )}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>Elektrisch verstellbar : {form.bedType === 'electric' ? 'Ja' : 'Nein'}</h6>
              <h6>
                {form.bedType === 'electric'
                  ? formatCurrency(getPrice(properties.prices, form.briete, 'upgrades', 'electric'))
                  : '-'}
              </h6>
            </div>
            <div className={styles.priceList}>
              <h6>Stauraum : {form.bedType === 'storage' ? 'Ja' : 'Nein'}</h6>
              <h6>
                {form.bedType === 'storage'
                  ? formatCurrency(
                      getPrice(properties.prices, form.briete, 'upgrades', form.storageType)
                    )
                  : '-'}
              </h6>
            </div>
          </div>

          <div className={styles.delivery}>
            <p>Lieferzeit: 3-4 Wochen</p>
            <p>Kostenloser Aufbau & Versand</p>
          </div>
        </div>

        <Direction
          backButton={{
            label: '',
            handleBack,
            imgStyles: {
              marginLeft: 0
            }
          }}
          nextButton={{ label: 'Checkout & Termin', handleNext: onCheckOut }}
        />
      </section>
    </div>
  );
}

export default DeinBett;
