import React, { useContext, useMemo } from 'react';
// import styles from "../AsideContent/Aussehen/Farbe/Farbe.module.scss";
import { ConfigContext } from '../../context';
import InputButton from '../InputButton/InputButton';
import styles from './Content.module.scss';
import NextButton from '../Directions/NextButton';
import { useCheckout } from '../../hooks/useCheckout';

function Stoffmuster({ data, children }: any) {
  const { stoffmusters, setStoffmusters, setStoffmusterAmount } = useContext(ConfigContext);

  const { checkout } = useCheckout();

  const handleChange = (e: any, material: string) => {
    const { value } = e.target;

    if (stoffmusters.length === 5) {
      return;
    }

    let _stoffmusters = [...stoffmusters];

    const foundIndex = _stoffmusters.findIndex(
      (fabric: any) => fabric.material === material && fabric.color === value
    );

    if (foundIndex === -1) {
      _stoffmusters.push({
        material,
        color: value
      });
    } else {
      _stoffmusters = _stoffmusters.filter((_, index: number) => index !== foundIndex);
    }

    setStoffmusters(_stoffmusters);

    let total = _stoffmusters.length * 2500;

    setStoffmusterAmount(total);
  };

  const isChecked = useMemo(
    () => (otherFabrics: any, material: string, color: string) => {
      const foundIndex = otherFabrics.findIndex(
        (fabric: any) => fabric.material === material && fabric.color === color
      );
      return foundIndex === -1 ? false : true;
    },
    []
  );

  return (
    <div className={`${styles.infoSlide} ${styles.farbe_section}`} id="stoffmuster">
      {children}

      <div className={styles.top}>
        <h4>Mollybett Stoffmuster · 25,00€</h4>
        <p>Wähle bis zu 5 verschiedene Stoffmuster</p>
      </div>

      <div
        className={styles.main}
        style={{
          marginTop: '20px'
        }}>
        <span>Feiner Stoff</span>
        <div className={styles.button}>
          {data.FeinerStoff?.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <InputButton
                item={item.value}
                image
                type="checkbox"
                circular
                checked={isChecked(stoffmusters, 'fineWeave', item.value)}
                name="farbe"
                handleChange={(e) => handleChange(e, 'fineWeave')}>
                <img src={item.image} alt="bed-style" />
              </InputButton>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <span>Grober Stoff</span>
        <div className={styles.button}>
          {data.GroberStoff?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                type="checkbox"
                circular
                checked={isChecked(stoffmusters, 'roughWeave', item.value)}
                handleChange={(e) => handleChange(e, 'roughWeave')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <span>Leder</span>
        <div className={styles.button}>
          {data.Leder?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                type="checkbox"
                circular
                checked={isChecked(stoffmusters, 'leather', item.value)}
                handleChange={(e) => handleChange(e, 'leather')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <span>Samt</span>
        <div className={styles.button}>
          {data.Samt?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                type="checkbox"
                circular
                checked={isChecked(stoffmusters, 'velvet', item.value)}
                handleChange={(e) => handleChange(e, 'velvet')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <NextButton
        label="Checkout & Termin"
        disabled={stoffmusters.length === 0}
        handleNext={() => {
          checkout(null, null, true);
        }}
      />
    </div>
  );
}

export default Stoffmuster;
