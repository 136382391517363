import React from 'react';
import styled from 'styled-components';
// import { Media } from '../../mixin';
import '../../scss/queries.scss';

const InputContainer = styled.div`
  input[type='radio'] {
    display: none;
  }
`;

const InputLabel = styled.label<{
  image?: boolean | string;
  circular?: boolean;
  disabled?: boolean;
  isBlackText?: boolean;
}>`
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: ${(props) => (props.circular ? '50%' : '6px')};
  font-weight: 400;
  font-size: 23px;
  /* margin-bottom: 12px; */
  padding: ${(props) => (props.circular ? '0' : '7px')};
  border: ${(props) => (props.circular ? '2px solid transparent' : '1px solid #2e2b2b')};
  cursor: pointer;
  height: ${(props) => (props.circular ? '90px' : 'auto')};
  width: ${(props) => (props.circular ? '90px' : '100%')};
  box-sizing: ${(props) => (props.circular ? 'content-box' : 'border-box')};

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  &:has(> input:checked) {
    background-color: ${(props) => (props.image ? undefined : 'black')};
    color: ${(props) => (props.isBlackText ? 'black' : 'white')} \;border\:${(props) =>
        props.image ? '2px solid #2e2b2b' : undefined}
      \;;
  }

  img {
    height: ${(props) => (props.circular ? '90px' : 'auto')};
    width: ${(props) => (props.circular ? '90px' : '100%')};
    border-radius: ${(props) => (props.circular ? '50%' : '0px')};
    border: ${(props) => (props.circular ? '2px' : '0px')} solid #2e2b2b;
    /* @media (max-width: 1200px) {
      height: ${(props) => (props.circular ? '80px' : 'auto')};
      width: ${(props) => (props.circular ? '80px' : '100%')};
    } */
  }
  div.input_options {
    height: ${(props) => (props.circular ? '90px' : 'auto')};
    width: ${(props) => (props.circular ? '90px' : '100%')};
    border-radius: ${(props) => (props.circular ? '50%' : '0px')};
    border: ${(props) => (props.circular ? '2px' : '0px')} solid #2e2b2b;
    /* @media (max-width: 1200px) {
      height: ${(props) => (props.circular ? '80px' : 'auto')};
      width: ${(props) => (props.circular ? '80px' : '100%')};
    } */
  }

  /* @media (max-width: 1200px) {
    height: ${(props) => (props.circular ? '80px' : 'auto')};
    width: ${(props) => (props.circular ? '80px' : '100%')};
  } */
`;

interface IInputButton {
  children: any;
  item: any;
  image?: string | boolean;
  checked?: boolean;
  handleChange?: (e: any) => void;
  name?: string;
  value?: string;
  circular?: boolean;
  disabled?: boolean;
  isBlackText?: boolean;
  type?: string;
  customStyles?: any;
}

export default function InputButton({
  children,
  item,
  image,
  checked,
  handleChange,
  name,
  circular,
  disabled,
  isBlackText,
  type = 'radio',
  customStyles
}: IInputButton) {
  return (
    <InputContainer>
      <InputLabel image={image} circular={circular} isBlackText={isBlackText} style={customStyles}>
        {children}
        <input
          type={type}
          id={item}
          name={name}
          value={item}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
      </InputLabel>
    </InputContainer>
  );
}
