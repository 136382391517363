import styles from "./Content.module.scss";

const topperInfo: Record<string, any>  = {
  "5cm": [
    {
      title: "Schaum-Topper",
      text: "Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor in- vidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu- sam et justo duo dolores et ea rebum.",
    },
    {
      title: "Funktion",
      text: "Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor in- vidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu- sam et justo duo dolores et ea rebum.",
    },
  ],

  "9cm": [
    {
      title: "Latex-Topper",
      text: "Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor in- vidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu- sam et justo duo dolores et ea rebum.",
    },
    {
      title: "Funktion",
      text: "Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor in- vidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accu- sam et justo duo dolores et ea rebum.",
    },
  ],
};

const TopperInfo = ({
  topper = "9cm",
  children
}: {
  topper?: string;
  children?: any
}) => {


  return (
    <div className={styles.infoSlide} id="topper">

      {children}

      {topperInfo[topper]?.map((info: any) => (
        <>
          <div className={styles.ibm_errechne}>
            <h4>{info.title}</h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum
            </p>
          </div>
        </>
      ))}
    </div>
  );
};

export default TopperInfo;