import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
// import styles from "./Button.module.scss";

interface IButton {
  label: string;
  transparent?: string | boolean;
  children?: any;
  order?: string | boolean;
  onClick: any;
  disabled?: boolean;
  customStyles?: any;
  isLightBorder?: boolean;
}

const ButtonStyle = styled.button<{
  transparent?: boolean | string;
}>`
  font-size: 0.85rem;
  padding: 4px 16px;
  color: ${(props) => (props.transparent ? '#2E2B2B' : 'white')};
  border: ${(props) => (props.transparent ? '1px solid #2E2B2B' : '1px solid #666666')};
  background: ${(props) => (props.transparent ? 'white' : '#666666')};
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.order {
    display: flex;
    flex-direction: row-reverse;
    border: none;
    justify-content: flex-start;
    align-items: center;
    background: transparent;

    * {
      margin-left: 16px;
      font-size: 24px;
      line-height: 24px;
      font-weight: 900;
    }
  }
  &.disabled {
    opacity: 0.4;
  }
  &.light_border {
    border: 1px solid #666666;
    color: #666666;
  }

  /* &.l_screen_button {
    @media (max-width: 768px) {
      display: none;
    }
  } */

  &.s_screen_button {
    display: none;

    @media (max-width: 768px) {
      text-align: left;
      padding-left: 0;
      border: 0;
      display: inline-block;
    }
  }
`;

export default function Button({
  label,
  transparent,
  children,
  order,
  onClick,
  disabled,
  customStyles,
  isLightBorder = false
}: IButton) {
  return (
    <div>
      <ButtonStyle
        transparent={transparent}
        className={classNames({
          order: Boolean(order),
          disabled,
          light_border: isLightBorder
        })}
        onClick={onClick}
        style={{ ...customStyles }}>
        {children}
        {label}
      </ButtonStyle>
    </div>
  );
}
