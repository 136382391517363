import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';

const NextWrapper = styled.div`
  Button {
    position: relative;
    // z-index: 1;
    width: 100%;
    margin: 25px auto 16px;
    padding-right: 0;
    bottom: 0px;
    font-size: 0.75rem;
    img {
      width: 35px;
    }
  }
`;

export interface BackButtonProps {
  label: string;
  handleBack?: (e: any) => void;
  imgStyles?: Record<string, string | number>;
  order?: boolean;
  [x: string]: any;
}

const BackButton = ({ label, handleBack, order = true, imgStyles, ...rest }: BackButtonProps) => {
  return (
    <NextWrapper {...rest}>
      <Button
        label={label}
        transparent
        order
        onClick={handleBack}
        customStyles={{
          fontWeight: '500',
          paddingLeft: 0
        }}>
        <img src="/images/icons/backIcon.svg" alt="back icon" style={imgStyles} />
      </Button>
    </NextWrapper>
  );
};

export default BackButton;
