import BackButton from '../Directions/BackButton';
import styles from './Content.module.scss';
import IBM from './IBM';
import { handleView } from '../../util/helper';
import TopperInfo from './TopperInfo';
import Contact from './Contact';
import Restart from './Restart';
import styled from 'styled-components';
import Mattrez from './Mattrez';
import Stoffmuster from './Stoffmuster';
import { useContext } from 'react';
import { ConfigContext } from '../../context';

interface IContent {
  topper?: string;
  data?: any;
  id: string;
}

const ContentButton = styled.div`
  .lg-screen {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .sm-screen {
    display: none;

    button {
      justify-content: flex-start;
      padding-left: 0;
      border: 0;
      img {
        margin-left: 0;
      }
    }

    @media (max-width: 768px) {
      text-align: left;
      padding-left: 0;
      border: 0;
      display: inline-block;
    }
  }
`;
const Content = ({ id = 'ibm', topper = '9cm', data }: IContent) => {
  const { setIsStoffmuster } = useContext(ConfigContext);

  const displayNextButton = () => {
    return (
      <ContentButton>
        <BackButton
          label="zurück"
          handleBack={() => {
            setIsStoffmuster(false);
            handleView(id);
          }}
          className="lg-screen"
        />
        <BackButton
          label=""
          handleBack={() => {
            setIsStoffmuster(false);
            handleView(id);
          }}
          order={false}
          className="sm-screen"
        />
      </ContentButton>
    );
  };

  switch (id) {
    case 'ibm':
      return (
        <div className={styles.infoSlide} id={id}>
          <IBM> {displayNextButton()}</IBM>
        </div>
      );

    case 'topper':
      return <TopperInfo topper={topper}>{displayNextButton()} </TopperInfo>;

    case 'contact':
      return <Contact>{displayNextButton()} </Contact>;

    case 'restart':
      return <Restart>{displayNextButton()} </Restart>;

    case 'matratze':
      return <Mattrez>{displayNextButton()} </Mattrez>;

    case 'stoffmuster':
      return <Stoffmuster data={data}>{displayNextButton()} </Stoffmuster>;

    default:
      return null;
  }
};

export default Content;
