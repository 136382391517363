import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import styles from './Main.module.scss';
import Bed from '../Bed/Bed';

const Main = forwardRef(function Main({ bedRef, bedCloneRef }: any) {
  const [, setScaleValue] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const mobileBedRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!!mobileBedRef?.current) {
      handleResize(mobileBedRef);
    }
  }, []);

  const handleResize = (mobileBedRef: React.RefObject<HTMLDivElement>) => {
    const value = ((mobileBedRef.current?.clientWidth as number) / 350) * 1.1;

    setScaleValue(value);
  };

  return (
    <div className={`${styles.container} ${styles.interior}`} ref={containerRef}>
      <Bed bedRef={bedRef} containerRef={containerRef} />
    </div>
  );
});

export default Main;
