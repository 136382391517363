import React, { useContext } from 'react';
import styles from './Aussehen.module.scss';
import NavAside from '../../NavAside/NavAside';
import { sections } from '../../../data';
import Kopfteil from '../../AsideContent/Aussehen/Kopfteil/Kopfteil';
import Farbe from '../../AsideContent/Aussehen/Farbe/Farbe';
import Fube from '../../AsideContent/Aussehen/Fube/Fube';
import { ConfigContext } from '../../../context';

function Aussehen({ data }: any) {
  const { step } = useContext(ConfigContext);

  return (
    <section className={styles.section}>
      <NavAside data={data.subMenu} />
      {step.subNavStep === 0 && <Kopfteil data={sections.aussehen} />}
      {step.subNavStep === 1 && <Farbe data={sections.aussehen} />}
      {step.subNavStep === 2 && <Fube data={sections.aussehen} />}
    </section>
  );
}

export default Aussehen;
