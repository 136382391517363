import { useContext, useRef, useState } from 'react';
import Aside from './components/Aside/Aside';
import Aussehen from './components/AsideContent/Aussehen/Aussehen';
import DeinBett from './components/AsideContent/DeinBett/DeinBett';
import Grobe from './components/AsideContent/Grobe/Grobe';
import Matratzes from './components/AsideContent/Matratzes/Matratzes';
import Upgrades from './components/AsideContent/Upgrades/Upgrades';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import { ConfigContext } from './context';
import { sections } from './data';
import './scss/main.scss';

import { AnimatePresence, motion } from 'framer-motion';
import setupLogRocketReact from 'logrocket-react';
import Content from './components/Contents/Content';
import FullPageLoader from './components/Loaders/FullPageLoader';

import LogRocket from 'logrocket';
import AccessPage from './components/AccessPage';
import { useAppConfigLoad } from './hooks/useAppLoadConfig';
import { useCheckout } from './hooks/useCheckout';

import { Toaster } from 'react-hot-toast';
import AppLoader from './components/Loaders/AppLoader';

const origin = window.location.origin;

if (!origin.includes('localhost')) {
  LogRocket.init('js6twm/test-project');
  setupLogRocketReact(LogRocket);

  LogRocket.identify('js6twm/test-project', {
    name: 'Joshua Oluikpe',
    email: 'joshuaoluikpe@gmail.com'
  });
}

function App() {
  const password = '8O7NCyqAIJ';

  const { step, pageLoader } = useContext(ConfigContext);

  const bedRef = useRef<HTMLDivElement | null>(null);
  const bedCloneRef = useRef<HTMLDivElement | null>(null);

  const [userPassword, setUserPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { checkout } = useCheckout();

  const onEnter = () => {
    if (userPassword === password) {
      setIsAuthenticated(true);
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.target;

    setUserPassword(value);
  };

  //Load default configuration
  const { loadingProperties, loadingImages } = useAppConfigLoad();

  const animation = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1.5
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 1
      }
    }
  };

  const animateApp = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 2
      }
    }
  };

  return (
    <>
      {loadingImages && loadingProperties ? (
        <AppLoader />
      ) : (
        <>
          <AnimatePresence>
            <motion.div variants={animateApp} initial="initial" animate="animate">
              {/*{!isAuthenticated && <AccessPage onEnter={onEnter} handleChange={handleChange} />}*/}
              <div className="container">
                <Header />
                <div className="sub-container">
                  <Main bedRef={bedRef} bedCloneRef={bedCloneRef} />
                  <Aside>
                    {step.navStep === 0 && (
                      <AnimatePresence>
                        <motion.div
                          variants={animation}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          <Grobe data={sections.grobe} />
                        </motion.div>
                      </AnimatePresence>
                    )}
                    {step.navStep === 1 && (
                      <AnimatePresence>
                        <motion.div
                          variants={animation}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          <Aussehen data={sections.aussehen} />
                        </motion.div>
                      </AnimatePresence>
                    )}
                    {step.navStep === 2 && (
                      <AnimatePresence>
                        <motion.div
                          variants={animation}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          <Matratzes data={sections.matratze} />
                        </motion.div>
                      </AnimatePresence>
                    )}
                    {step.navStep === 3 && (
                      <AnimatePresence>
                        <motion.div
                          variants={animation}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          <Upgrades data={sections.stauraum} />
                        </motion.div>
                      </AnimatePresence>
                    )}
                    {step.navStep === 4 && (
                      <AnimatePresence>
                        <motion.div
                          variants={animation}
                          initial="initial"
                          animate="animate"
                          exit="exit">
                          <DeinBett
                            onCheckOut={() => {
                              checkout(bedRef, bedCloneRef, false);
                            }}
                          />
                        </motion.div>
                      </AnimatePresence>
                    )}
                    <Content id="contact" />
                    <Content id="restart" />
                    <Content id="stoffmuster" data={sections.aussehen} />
                  </Aside>
                </div>
                {pageLoader && <FullPageLoader text="Zahlung verarbeiten..." />}
                <div
                  id="bedClone"
                  ref={bedCloneRef}
                  style={{ position: 'absolute', top: 0, left: '-1000%' }}
                />
              </div>
              <Toaster />
            </motion.div>
          </AnimatePresence>
        </>
      )}
    </>
  );
}

export default App;
