import React, { useContext } from 'react';
import 'react-tabs/style/react-tabs.css';
import styles from './NavAside.module.scss';
import { ConfigContext } from '../../context';
import { subMenus } from '../../data';

interface INavAside {
  data: any;
}

const NavAside = ({ data }: INavAside) => {
  const { step, currentState, setStep } = useContext(ConfigContext);

  return (
    <>
      <ul className={styles.navList}>
        {data?.map((item: string, i: number) => {
          const itemIndex = subMenus.findIndex((subNav) => subNav === item);
          return (
            <li
              className={`${itemIndex <= step.subNavStep ? styles.active : styles.navItems} ${
                i === 0 ? styles['navItems--first'] : ''
              }`}
              key={itemIndex}
              onClick={() => {
                if (itemIndex <= currentState.currentSubNavState) {
                  setStep((step: any) => ({
                    ...step,
                    subNavStep: itemIndex
                  }));
                }
              }}>
              {item}
              <span />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default NavAside;
