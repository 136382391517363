import React from 'react';
import classNames from 'classnames';
import { useContext } from 'react';
import { ConfigContext } from '../../../context';
import { nav } from '../../../data';
import { validateField } from '../../../util/validation';
import InputButton from '../../InputButton/InputButton';
import NextButton from '../../Directions/NextButton';
import styles from './Grobe.module.scss';
import { IForm } from '../../../types';

function Grobe({ data }: any) {
  const {
    form,
    setForm,
    setErrors,
    fields,
    errors,
    setStep,
    defaultValues,
    setIsImageLoaded1,
    setIsImageLoaded3,
    setIsImageLoaded4,
    setIsImageLoaded5,
    setIsImageLoaded6
  } = useContext(ConfigContext);

  const bedWidthWithSingleConfig = ['90', '100', '120'];

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    const isDefaultBedType = bedWidthWithSingleConfig.includes(value);

    if (defaultValues.briete !== parseInt(value) && name === 'briete') {
      setIsImageLoaded1(false);
      setIsImageLoaded3(false);
      setIsImageLoaded4(false);
      setIsImageLoaded5(false);
      setIsImageLoaded6(false);
    }

    setForm((prevForm: IForm) => {
      const matratzeType = prevForm.matratze.type
        ? prevForm.bedType === 'electric' && !isDefaultBedType
          ? 'split'
          : isDefaultBedType
          ? 'full'
          : prevForm.matratze.type
        : null;

      const topperType = prevForm.bedType === 'electric' && !isDefaultBedType ? 'split' : 'full';

      return {
        ...prevForm,
        [name]: Number(value),
        matratze: {
          ...prevForm.matratze,
          matratzeWeight2: isDefaultBedType ? null : prevForm.matratze.matratzeWeight2,
          type: matratzeType
        },
        topper: {
          ...prevForm.topper,
          type: topperType
        }
      };
    });
  };

  const handleNext = () => {
    const errors = validateField(form, fields['grobe']);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        ...step,
        navStep: step.navStep + 1,
        state: nav[step.navStep + 1]
      }));
    }
  };

  return (
    <section className={styles.section} id="aside">
      <div className={styles.slider}></div>
      <div className={styles.header}>
        <h2>Größe</h2>
        <p>Konfiguriere dein Mollybett in der Größe, die du für dich brauchst</p>
      </div>

      <div className={styles.main}>
        <p className={classNames(styles.mainTitle, { error: errors.briete })}>Briete (in cm)</p>
        <div className={styles.buttons}>
          {data.briete?.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <InputButton
                item={item}
                value={item}
                name="briete"
                checked={item === form.briete}
                handleChange={handleChange}>
                {item}
              </InputButton>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={`${styles.main} ${!form.briete ? styles.disabled : ''}`}>
        <p className={classNames(styles.mainTitle, { error: errors.lange })}>Länge (in cm)</p>
        <div className={styles.buttons}>
          {data.lange?.map((item: any, index: any) => (
            <div key={index} className={styles.button}>
              <InputButton
                item={item}
                name="lange"
                checked={item === form.lange}
                handleChange={handleChange}
                disabled={!form.briete}>
                {item}
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <NextButton label="weiter" handleNext={handleNext} />
    </section>
  );
}

export default Grobe;
