import { createContext, useEffect, useState } from 'react';
import { nav } from './data';
import { calTotal, updateUrlParams } from './util/helper';
import { IForm } from './types';

window.Buffer = window.Buffer || require('buffer').Buffer;

export const ConfigContext = createContext<any>({});

const ConfigContextProvider = ({ children }: { children: JSX.Element }) => {
  const [step, setStep] = useState({
    navStep: 0,
    state: nav[0],
    subNavStep: 0
  });
  const [currentState, setCurrentState] = useState({
    currentNavState: 0,
    currentSubNavState: 0
  });

  //Image loaders
  const [isImageLoaded1, setIsImageLoaded1] = useState(false);
  const [isImageLoaded2, setIsImageLoaded2] = useState(true);
  const [isImageLoaded3, setIsImageLoaded3] = useState(false);
  const [isImageLoaded4, setIsImageLoaded4] = useState(true);
  const [isImageLoaded5, setIsImageLoaded5] = useState(true);
  const [isImageLoaded6, setIsImageLoaded6] = useState(false);

  const [IsStoffmuster, setIsStoffmuster] = useState(false);
  const [isInterior, setIsInterior] = useState(true);
  const [stoffmusters, setStoffmusters] = useState([]);
  const [stoffmusterAmount, setStoffmusterAmount] = useState(0);
  const [pageLoader, setPageLoader] = useState(false);
  const [properties, setProperties] = useState({
    prices: null,
    configurations: null
  });

  const fields = {
    grobe: ['briete', 'lange'],
    kopfteil: ['kopfteil.headboard'],
    farbe: ['farbe.color'],
    matratze: ['matratze.matratzeWeight1'],
    fube: ['fube.category', 'fube.type'],
    topper: ['topper.width']
  };

  const defaultValues = {
    briete: 180,
    lange: 200,
    kopfteil: {
      headboard: 'headboard1',
      width: '15cm'
    },
    farbe: {
      color: 'dark_grey',
      material: 'fineWeave'
    },
    matratze: {
      matratzeWeight1: null,
      matratzeWeight2: null,
      type: 'full'
    },
    fube: {
      category: 'leg1',
      type: 'dark_wood'
    },
    topper: { type: 'full', width: '9cm' },
    bedType: 'standard',
    storageType: null
  } as IForm;

  //

  const [form, setForm] = useState<IForm>({
    briete: null,
    lange: null,
    kopfteil: {
      headboard: null,
      width: null
    },
    farbe: {
      color: null,
      material: null
    },
    matratze: {
      matratzeWeight1: null,
      matratzeWeight2: null,
      type: null
    },
    fube: {
      category: null,
      type: null
    },
    topper: { type: 'full', width: null },
    bedType: 'standard',
    storageType: null
  });

  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState(0);

  //Update state on change in
  useEffect(() => {
    if (form.briete) {
      updateUrlParams(form, {
        step,
        currentState
      });
    }
  }, [form, step, currentState]);

  useEffect(() => {
    if (form.briete) {
      const total = calTotal(form, properties.prices);
      setAmount(total);
    }
  }, [form, properties.prices]);

  useEffect(() => {
    if (form.briete) {
      if (form.bedType === 'storage') {
        setIsImageLoaded3(true);
      }
    }
  }, [form]);

  useEffect(() => {
    const _errors = { ...errors };

    Object.keys(errors).forEach((field: string) => {
      const value = field.includes('.')
        ? //@ts-ignore
          form[field.split('.')[0]][field.split('.')[1]]
        : //@ts-ignore
          form[field];

      if (value) {
        //@ts-ignore
        if (_errors[field]) delete _errors[field];
        setErrors({ ..._errors });
      }
    });
  }, [form, errors]);

  useEffect(() => {
    const section = document.querySelector('.error');

    const asideSection = document.querySelector('#aside');

    if (section) {
      asideSection?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [errors]);

  useEffect(() => {
    if (
      step.navStep > currentState.currentNavState ||
      step.subNavStep > currentState.currentSubNavState
    ) {
      setCurrentState({
        currentNavState: step.navStep,
        currentSubNavState: step.subNavStep
      });

      // localStorage.setItem("state", JSON.stringify(currentState));
    }
  }, [step, currentState]);

  const resetForm = () => {
    // localStorage.removeItem("bed_config");
    // localStorage.removeItem("state");
    setForm({
      briete: null,
      lange: null,
      kopfteil: {
        headboard: null,
        width: null
      },
      farbe: {
        material: null,
        color: null
      },
      matratze: {
        matratzeWeight1: null,
        matratzeWeight2: null,
        type: null
      },
      fube: {
        category: null,
        type: null
      },
      topper: { width: null, type: 'full' },
      bedType: 'standard',
      storageType: null
    });
    setAmount(1790);
    setStep({
      navStep: 0,
      state: nav[0],
      subNavStep: 0
    });

    const url = new URL(window.location.href);

    if (url.searchParams.has('state')) url.searchParams.delete('state');

    window.history.pushState({}, '', url.toString());
  };

  return (
    <ConfigContext.Provider
      value={{
        form,
        setForm,
        errors,
        setErrors,
        defaultValues,
        properties,
        setProperties,
        fields,
        amount,
        step,
        setStep,
        currentState,
        resetForm,
        isImageLoaded1,
        setIsImageLoaded1,
        isImageLoaded2,
        setIsImageLoaded2,
        isImageLoaded3,
        setIsImageLoaded3,
        isImageLoaded4,
        setIsImageLoaded4,
        isImageLoaded5,
        setIsImageLoaded5,
        isImageLoaded6,
        setIsImageLoaded6,
        setAmount,
        setCurrentState,
        IsStoffmuster,
        setIsStoffmuster,
        isInterior,
        setIsInterior,
        setStoffmusters,
        stoffmusters,
        stoffmusterAmount,
        setStoffmusterAmount,
        pageLoader,
        setPageLoader
      }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
