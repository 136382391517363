import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import 'tippy.js/animations/shift-toward.css';
import { ConfigContext } from '../../context';
// import Tippy from '../Tippy.';
import styles from './Bed.module.scss';
import { useResize } from '../../hooks/useResize';

const Bed = forwardRef(function Bed({ bedRef, containerRef }: any) {
  const {
    form,
    defaultValues,
    properties,
    isImageLoaded1,
    setIsImageLoaded1,
    isImageLoaded2,
    setIsImageLoaded2,
    isImageLoaded3,
    setIsImageLoaded3,
    isImageLoaded4,
    setIsImageLoaded4,
    isImageLoaded5,
    setIsImageLoaded5,
    setIsImageLoaded6
  } = useContext(ConfigContext);

  const [positions, setPositions] = useState<{
    position1: string;
    position2: string;
    position3: string;
    position4: string;
    position5: string;
    position6: string;
  }>({
    position1: '',
    position2: '',
    position3: '',
    position4: '',
    position5: '',
    position6: ''
  });

  const handleResize = useCallback(() => {
    const width = containerRef.current.clientWidth;

    let value = (containerRef.current.clientWidth / 550) * 1.1;

    if (width < 576) {
      value = (containerRef.current.clientWidth / 550) * 1.5;
    }

    setScaleValue(value);
  }, [containerRef]);

  useEffect(() => {
    if (!!containerRef.current) {
      handleResize();
    }
  }, [containerRef, handleResize]);

  const [scaleValue, setScaleValue] = useState(0);

  useResize(handleResize);

  // const dots = [
  //   {
  //     header: 'Headboard',
  //     body: 'Lorem ipsum dolor sit amet, con- setetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  //     top: 45,
  //     left: 30,
  //     placement: 'right'
  //   },
  //   {
  //     header: 'Topper',
  //     body: 'Lorem ipsum dolor sit amet, con- setetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  //     top: 65,
  //     left: 50,
  //     placement: 'right'
  //   },
  //   {
  //     header: 'Matratze',
  //     body: 'Lorem ipsum dolor sit amet, con- setetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  //     top: 62,
  //     left: 17,
  //     placement: 'right'
  //   },
  //   {
  //     header: 'Base',
  //     body: 'Lorem ipsum dolor sit amet, con- setetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  //     top: 73,
  //     left: 25,
  //     placement: 'right'
  //   },

  //   {
  //     header: 'Legs',
  //     body: 'Lorem ipsum dolor sit amet, con- setetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
  //     top: 82,
  //     left: 63,
  //     placement: 'right'
  //   }
  // ];

  useEffect(() => {
    if (properties.configurations) {
      const {
        fube: defaultFube,
        kopfteil: defaultkopfteil,
        farbe: defaultFarbe,
        matratze: defaultMatratze,
        topper: defaultTopper,
        bedType: defaultBedType
      } = defaultValues;

      let { fube, kopfteil, farbe, matratze, topper, bedType, storageType } = form;

      const briete = form.briete || defaultValues.briete;

      const configuration = properties.configurations[briete];

      kopfteil = kopfteil.headboard ? kopfteil : defaultkopfteil;

      let newFarbe = { ...farbe };
      if (farbe.material) {
        newFarbe.color = farbe.color;
      } else {
        newFarbe = defaultFarbe;
      }

      let newFube = { ...fube };

      if (!fube.category) {
        newFube.category = defaultFube.category;
      }

      if (!fube.type) {
        newFube.type = defaultFube.type;
      }

      if (!matratze.type) {
        matratze = defaultMatratze;
      }

      if (!topper.width) {
        topper = defaultTopper;
      }

      bedType = bedType || defaultBedType;

      //@ts-ignore
      let position1 = configuration.headboards[kopfteil.headboard][newFarbe.material].find(
        (_el: any) => _el.type === newFarbe.color
      ).image;

      let position2 = null;
      if (storageType) {
        position2 = configuration.storage[storageType][newFarbe.material].find(
          (_el: any) => _el.type === newFarbe.color
        ).image;
      }

      let position3 = configuration.legs[newFube.category].find(
        (_el: any) => _el.type === newFube.type
      ).image;

      let position4 = configuration.base[bedType][newFarbe.material].find(
        (_el: any) => _el.type === newFarbe.color
      ).image;

      let position5 = configuration.matratze[bedType][matratze.type][newFarbe.material].find(
        (_el: any) => _el.type === newFarbe.color
      ).image;

      let position6 = configuration.topper[bedType][topper.type].find(
        (_el: any) => _el.width === topper.width
      ).image;

      setPositions({
        position1,
        position2,
        position3,
        position4,
        position5,
        position6
      });
    }
  }, [properties.configurations, form, defaultValues]);

  const briete = form.briete || defaultValues.briete;

  return (
    <div className={styles.main_container}>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(/images/backgroundImages/${briete}cm.png)`,
          transform: `scale(${scaleValue})`,
          backgroundColor: '#fbfbfb',
          backgroundSize: 'contain'
        }}>
        {(!isImageLoaded1 ||
          !isImageLoaded2 ||
          !isImageLoaded3 ||
          !isImageLoaded4 ||
          !isImageLoaded5) && (
          <div className={styles.loader}>
            <ReactLoading type="cylon" color="#666666" width={'10%'} />
          </div>
        )}

        {/* <div> {form.briete === null && dots.map((dot, i) => <Tippy content={dot} key={i} />)}</div> */}

        <div className={styles.bed} ref={bedRef} id="bedSection">
          {positions.position1 && (
            <img
              src={positions.position1}
              className={styles.img}
              alt="HeadBoard"
              onLoad={() => {
                setIsImageLoaded1(true);
              }}
            />
          )}

          {form.bedType === 'storage' && form.storageType && positions.position2 && (
            <img
              src={positions.position2}
              className={styles.img}
              alt="Storage"
              onLoad={() => {
                setIsImageLoaded2(true);
              }}
            />
          )}

          {form.bedType !== 'storage' && positions.position3 && (
            <img
              src={positions.position3}
              className={styles.img}
              alt="Leg"
              onLoad={() => {
                setIsImageLoaded3(true);
              }}
            />
          )}

          {positions.position4 && (
            <img
              src={positions.position4}
              className={styles.img}
              alt="Base"
              onLoad={() => setIsImageLoaded4(true)}
            />
          )}

          {positions.position5 && (
            <img
              src={positions.position5}
              className={styles.img}
              alt="Mattress"
              onLoad={() => setIsImageLoaded5(true)}
            />
          )}

          {positions.position6 && (
            <img
              src={positions.position6}
              className={styles.img}
              alt="Topper"
              onLoad={() => setIsImageLoaded6(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Bed;
