import styled, { keyframes } from 'styled-components';
import { BarLoader } from 'react-spinners';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255);
  z-index: 100000;
  position: fixed;
  left: 0%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
`;

const anims = keyframes`
to {
      stroke-dashoffset: 0;
    }
`;

const fill = keyframes`
from {
      fill: transparent;
    }
    to {
      fill: #000;
    }
`;

const Logo = styled.div`
  margin-bottom: 20px;
  #Ebene_2 {
    animation: ${fill} 0.5s ease forwards 2s;
  }
  #Ebene_2 path:nth-child(1) {
    stroke-dasharray: 3158;
    stroke-dashoffset: 3158;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(2) {
    stroke-dasharray: 1374;
    stroke-dashoffset: 1374;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(3) {
    stroke-dasharray: 963;
    stroke-dashoffset: 963;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(4) {
    stroke-dasharray: 963;
    stroke-dashoffset: 963;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(5) {
    stroke-dasharray: 2374;
    stroke-dashoffset: 2374;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(6) {
    stroke-dasharray: 1374;
    stroke-dashoffset: 1374;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(7) {
    stroke-dasharray: 1374;
    stroke-dashoffset: 1374;
    animation: ${anims} 3s ease forwards;
  }
  #Ebene_2 path:nth-child(8) {
    stroke-dasharray: 1374;
    stroke-dashoffset: 1374;
    animation: ${anims} 4s ease forwards;
  }
  #Ebene_2 path:nth-child(9) {
    stroke-dasharray: 2158;
    stroke-dashoffset: 2158;
    animation: ${anims} 4s ease forwards;
  }
`;

const Text = styled.p`
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
`;
export default function AppLoader({ text }: any) {
  return (
    <Wrapper>
      <div>
        <Logo>
          <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 657.7 142" fill="none">
            <defs>
              <style>
                {`.cls-1 {
                  // stroke-width: 1px;
                
                  stroke:#000
                }`}
              </style>
            </defs>
            <g id="Ebene_1-2">
              <path
                className="cls-1"
                d="m10.58,111.35c-3.6,0-6.26-1.04-7.99-3.14-1.73-2.09-2.59-5.08-2.59-8.97V20.14c0-3.89.86-6.88,2.59-8.97,1.73-2.09,4.39-3.14,7.99-3.14l10.73,12.11v79.1c0,3.89-.88,6.88-2.63,8.97-1.75,2.09-4.45,3.14-8.1,3.14Zm41.67-28.02c-1.85,0-3.4-.32-4.67-.95-1.26-.63-2.38-1.51-3.36-2.63-.97-1.12-2.31-2.87-4.01-5.25-.05-.1-.1-.18-.15-.26-.05-.07-.1-.13-.15-.18L4.52,24.01l6.06-15.98c1.95,0,3.58.32,4.89.95s2.54,1.58,3.68,2.84,2.62,3.16,4.42,5.69l36.71,52.17-8.03,13.64Zm0,0l-7.95-13.64,36.71-52.17c1.75-2.53,3.21-4.43,4.38-5.69s2.43-2.21,3.79-2.84c1.36-.63,2.99-.95,4.89-.95l6.06,15.98-35.46,49.91c-1.85,2.63-3.28,4.55-4.3,5.76-1.02,1.22-2.15,2.13-3.39,2.74s-2.81.91-4.71.91Zm41.81,28.02c-3.55,0-6.19-1.04-7.92-3.14-1.73-2.09-2.59-5.08-2.59-8.97V20.14l10.51-12.11c3.65,0,6.35,1.04,8.1,3.14,1.75,2.09,2.63,5.08,2.63,8.97v79.1c0,3.89-.88,6.88-2.63,8.97-1.75,2.09-4.45,3.14-8.1,3.14Z"
              />
              <path
                className="cls-1"
                d="m157.47,111.5c-6.91,0-13.06-1.54-18.46-4.63-5.4-3.09-9.62-7.41-12.66-12.96-3.04-5.54-4.56-11.92-4.56-19.12s1.52-13.5,4.56-19.05c3.04-5.54,7.26-9.87,12.66-12.99,5.4-3.11,11.55-4.67,18.46-4.67s13.22,1.56,18.64,4.67,9.64,7.44,12.66,12.99c3.02,5.55,4.52,11.9,4.52,19.05s-1.51,13.51-4.52,19.08-7.24,9.9-12.66,12.99-11.64,4.63-18.64,4.63Zm0-18.1c3.4,0,6.25-.72,8.54-2.15,2.29-1.43,4-3.54,5.14-6.31,1.14-2.77,1.71-6.16,1.71-10.14s-.57-7.3-1.71-10.07c-1.14-2.77-2.86-4.89-5.14-6.35-2.29-1.46-5.13-2.19-8.54-2.19s-6.09.73-8.35,2.19c-2.26,1.46-3.97,3.58-5.11,6.35-1.14,2.77-1.71,6.13-1.71,10.07s.57,7.37,1.71,10.14c1.14,2.77,2.84,4.88,5.11,6.31,2.26,1.44,5.05,2.15,8.35,2.15Z"
              />
              <path
                className="cls-1"
                d="m220.96,111.35c-3.6,0-6.26-1.04-7.99-3.14-1.73-2.09-2.59-5.08-2.59-8.97V20.14c0-3.89.86-6.88,2.59-8.97,1.73-2.09,4.39-3.14,7.99-3.14s6.35,1.04,8.1,3.14c1.75,2.09,2.63,5.08,2.63,8.97v79.1c0,3.89-.88,6.88-2.63,8.97-1.75,2.09-4.45,3.14-8.1,3.14Z"
              />
              <path
                className="cls-1"
                d="m265.47,111.35c-3.6,0-6.26-1.04-7.99-3.14-1.73-2.09-2.59-5.08-2.59-8.97V20.14c0-3.89.86-6.88,2.59-8.97,1.73-2.09,4.39-3.14,7.99-3.14s6.35,1.04,8.1,3.14c1.75,2.09,2.63,5.08,2.63,8.97v79.1c0,3.89-.88,6.88-2.63,8.97-1.75,2.09-4.45,3.14-8.1,3.14Z"
              />
              <path
                className="cls-1"
                d="m292.17,54.14c-1.17-2.53-1.75-4.69-1.75-6.5,0-1.36.42-2.77,1.28-4.23s2.05-2.69,3.61-3.69c1.55-1,3.36-1.49,5.4-1.49s3.76.61,5.44,1.82c1.68,1.22,3.03,2.97,4.05,5.25l23.35,51.01-14.67,15.03-26.71-57.21Zm15.76,87.86c-1.95,0-3.63-.46-5.04-1.39s-2.46-2.07-3.14-3.43c-.68-1.36-1.02-2.65-1.02-3.86,0-1.85.35-3.45,1.06-4.78.71-1.34,1.84-2.79,3.39-4.34.1-.05.18-.11.25-.18.07-.08.13-.13.18-.18,2.43-2.48,4.65-5.17,6.64-8.06,1.99-2.9,4.09-6.72,6.27-11.49l26.42-58.96c1.02-2.28,2.37-4.04,4.05-5.25,1.68-1.21,3.49-1.82,5.44-1.82,2.09,0,3.92.5,5.47,1.49,1.56,1,2.75,2.23,3.58,3.69.83,1.46,1.24,2.9,1.24,4.31,0,1.9-.58,4.04-1.75,6.42l-26.41,57.72c-2.77,5.98-5.64,11.37-8.61,16.16-2.97,4.79-6.3,8.47-10,11.05-2.63,1.94-5.3,2.92-8.03,2.92Z"
              />
              <path
                className="cls-1"
                d="m384.92,111.35c-3.41,0-5.94-.98-7.59-2.96-1.66-1.97-2.48-4.75-2.48-8.35V11.17c0-3.6.83-6.36,2.48-8.28,1.65-1.92,4.18-2.88,7.59-2.88s5.91.96,7.52,2.88c1.6,1.92,2.41,4.68,2.41,8.28v88.88c0,3.6-.8,6.38-2.41,8.35-1.61,1.97-4.11,2.96-7.52,2.96Zm27.22.15c-6.13,0-11.29-1.8-15.47-5.4s-7.3-8.2-9.34-13.79-3.06-11.43-3.06-17.51,1.02-11.92,3.06-17.51,5.16-10.19,9.34-13.79c4.18-3.6,9.34-5.4,15.47-5.4,6.81,0,12.7,1.53,17.66,4.6,4.96,3.07,8.76,7.36,11.38,12.88,2.63,5.52,3.94,11.93,3.94,19.23s-1.31,13.71-3.94,19.23c-2.63,5.52-6.42,9.81-11.38,12.88-4.96,3.07-10.85,4.6-17.66,4.6Zm-2.34-18.1c3.31,0,6.07-.75,8.28-2.26,2.21-1.51,3.86-3.65,4.93-6.42,1.07-2.77,1.6-6.08,1.6-9.92s-.54-7.16-1.6-9.96c-1.07-2.8-2.71-4.95-4.93-6.46-2.21-1.51-4.97-2.26-8.28-2.26s-6.08.75-8.32,2.26c-2.24,1.51-3.9,3.66-5,6.46s-1.64,6.12-1.64,9.96.55,7.15,1.64,9.92,2.76,4.92,5,6.42c2.24,1.51,5.01,2.26,8.32,2.26Z"
              />
              <path
                className="cls-1"
                d="m471.39,67.06h36.85l-6.42,5.33c.73-1.65,1.09-3.24,1.09-4.74,0-3.84-1.06-6.87-3.17-9.09-2.12-2.21-4.95-3.32-8.5-3.32-3.36,0-6.27.79-8.76,2.37s-4.38,3.83-5.69,6.75c-1.31,2.92-1.97,6.4-1.97,10.43s.74,7.19,2.23,10.03,3.6,5.03,6.35,6.57c2.75,1.53,5.95,2.3,9.6,2.3,3.06,0,5.62-.29,7.66-.88,2.04-.58,4.28-1.51,6.71-2.77,2.14-1.12,4.18-1.68,6.13-1.68,1.31,0,2.6.34,3.87,1.02,1.26.68,2.3,1.66,3.1,2.92s1.2,2.7,1.2,4.31c0,1.17-.18,2.32-.55,3.47-.36,1.14-1.03,2.28-2.01,3.39-2.24,2.53-5.77,4.5-10.58,5.91-4.82,1.41-10,2.11-15.54,2.11-7.35,0-13.82-1.53-19.41-4.6s-9.91-7.38-12.95-12.95-4.56-11.96-4.56-19.15,1.47-13.51,4.42-19.08,7.09-9.9,12.44-12.99c5.35-3.09,11.43-4.63,18.24-4.63,5.64,0,10.65,1.13,15.03,3.39,4.38,2.26,7.81,5.64,10.29,10.14,2.48,4.5,3.72,10.01,3.72,16.53,0,3.16-.49,5.73-1.46,7.7-.97,1.97-2.54,3.42-4.71,4.34s-5.02,1.39-8.57,1.39h-34.08v-14.52Z"
              />
              <path
                className="cls-1"
                d="m538.16,57.65c-3.31,0-5.89-.73-7.74-2.19-1.85-1.46-2.77-3.72-2.77-6.78s.94-5.29,2.81-6.82,4.44-2.3,7.7-2.3h31.96c3.21,0,5.73.77,7.55,2.3,1.82,1.53,2.74,3.8,2.74,6.82s-.9,5.33-2.7,6.78c-1.8,1.46-4.33,2.19-7.59,2.19h-31.96Zm29.33,53.78c-6.47,0-11.58-1.04-15.32-3.1-3.75-2.07-6.37-4.91-7.88-8.54-1.51-3.62-2.26-7.99-2.26-13.1V30.87c0-3.65.82-6.44,2.45-8.39,1.63-1.94,4.17-2.92,7.63-2.92s5.85.97,7.48,2.92c1.63,1.95,2.44,4.75,2.44,8.39v54.07c0,2.09.21,3.72.62,4.89.41,1.17,1.23,2.04,2.45,2.63,1.21.58,2.99.88,5.33.88,3.36,0,5.91.61,7.66,1.82,1.75,1.22,2.63,3.31,2.63,6.28,0,3.26-1.07,5.74-3.21,7.44-2.14,1.7-5.47,2.56-10,2.56Z"
              />
              <path
                className="cls-1"
                d="m596.24,57.65c-3.31,0-5.89-.73-7.74-2.19-1.85-1.46-2.77-3.72-2.77-6.78s.94-5.29,2.81-6.82,4.44-2.3,7.7-2.3h31.96c3.21,0,5.73.77,7.55,2.3,1.82,1.53,2.74,3.8,2.74,6.82s-.9,5.33-2.7,6.78c-1.8,1.46-4.33,2.19-7.59,2.19h-31.96Zm29.33,53.78c-6.47,0-11.58-1.04-15.32-3.1-3.75-2.07-6.37-4.91-7.88-8.54-1.51-3.62-2.26-7.99-2.26-13.1V30.87c0-3.65.82-6.44,2.45-8.39,1.63-1.94,4.17-2.92,7.63-2.92s5.85.97,7.48,2.92c1.63,1.95,2.44,4.75,2.44,8.39v54.07c0,2.09.21,3.72.62,4.89.41,1.17,1.23,2.04,2.45,2.63,1.21.58,2.99.88,5.33.88,3.36,0,5.91.61,7.66,1.82,1.75,1.22,2.63,3.31,2.63,6.28,0,3.26-1.07,5.74-3.21,7.44-2.14,1.7-5.47,2.56-10,2.56Z"
              />
              <path
                className="cls-1"
                d="m647.58,22.57c-1.86,0-3.57-.44-5.12-1.33-1.54-.89-2.76-2.11-3.65-3.66-.89-1.55-1.33-3.26-1.33-5.13s.44-3.57,1.33-5.13c.89-1.55,2.11-2.78,3.65-3.68,1.54-.9,3.25-1.35,5.12-1.35s3.59.45,5.13,1.35c1.54.9,2.75,2.12,3.65,3.68.9,1.55,1.35,3.26,1.35,5.13s-.44,3.57-1.33,5.11c-.89,1.55-2.11,2.77-3.65,3.67s-3.26,1.35-5.14,1.35Zm0-2.74c1.43,0,2.69-.31,3.77-.95,1.08-.63,1.92-1.5,2.53-2.62.6-1.11.91-2.39.91-3.82s-.3-2.73-.91-3.85c-.6-1.11-1.45-1.98-2.54-2.6-1.09-.62-2.34-.93-3.76-.93s-2.69.31-3.78.93c-1.09.62-1.93,1.49-2.52,2.6-.6,1.11-.89,2.4-.89,3.85s.3,2.71.89,3.82c.6,1.11,1.44,1.99,2.52,2.62s2.35.95,3.78.95Zm-2.51-2.2c-.45,0-.78-.14-1-.41s-.32-.65-.32-1.11v-7.64c0-.47.11-.84.32-1.13.22-.28.55-.43,1-.43s.79.14,1.01.43c.22.29.34.66.34,1.13v7.64c0,.47-.11.84-.32,1.11s-.56.41-1.02.41Zm0-3.32v-2.41h2.49c.5,0,.89-.12,1.17-.35.28-.23.41-.55.41-.95s-.14-.68-.41-.91c-.28-.22-.66-.34-1.17-.34h-2.49v-2.46h2.43c1.42,0,2.49.33,3.24.98.74.66,1.11,1.62,1.11,2.87,0,1.17-.4,2.06-1.2,2.66-.8.6-1.85.89-3.15.89h-2.43Zm5.49,3.32c-.28,0-.51-.05-.7-.16-.19-.1-.4-.29-.62-.57l-2.54-3.21,2.56-.83,2.28,2.51c.26.26.39.6.39,1.01,0,.28-.13.55-.39.83s-.59.41-.98.41Z"
              />
            </g>
          </svg>
          {/* <svg
            id="logo"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="111.000000pt"
            height="48.000000pt"
            viewBox="0 0 111.000000 48.000000"
            fill="none"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)">
              <path
                d="M10 305 l0 -165 80 0 c73 0 80 2 80 20 0 18 -7 20 -55 20 l-55 0 0
145 0 145 -25 0 -25 0 0 -165z"
                stroke="#666"
                strokeWidth="4"
              />
              <path
                d="M293 399 c-76 -22 -109 -120 -67 -201 43 -84 192 -73 230 17 19 46
18 79 -5 124 -27 52 -96 79 -158 60z m82 -45 c21 -8 45 -56 45 -89 0 -34 -50
-85 -83 -85 -34 0 -73 34 -80 70 -7 35 7 79 30 98 18 13 61 16 88 6z"
                stroke="#666"
                strokeWidth="4"
              />
              <path
                d="M580 392 c-61 -30 -88 -118 -55 -182 35 -68 108 -89 174 -49 l34 20
-6 -42 c-3 -23 -15 -51 -26 -62 -28 -29 -83 -28 -115 2 -13 13 -33 21 -45 19
l-21 -3 20 -32 c34 -56 136 -68 194 -22 36 28 46 76 46 227 l0 132 -25 0 c-16
0 -25 -6 -25 -15 0 -19 2 -19 -45 5 -48 24 -60 25 -105 2z m118 -54 c30 -33
36 -70 18 -107 -30 -58 -82 -67 -127 -22 -21 22 -29 39 -29 66 0 74 89 114
138 63z"
                stroke="#666"
                strokeWidth="4"
              />
              <path
                d="M923 399 c-51 -15 -83 -61 -83 -120 0 -88 46 -139 125 -139 103 0
164 101 119 196 -26 53 -97 82 -161 63z m102 -64 c36 -35 34 -88 -4 -126 -35
-34 -68 -37 -105 -8 -35 27 -37 101 -4 136 30 32 81 31 113 -2z"
                stroke="#666"
                strokeWidth="4"
              />
            </g>
          </svg> */}
        </Logo>
        <BarLoader color="#666" height={5} width={250} />
        <Text>Konfigurator einrichten</Text>
      </div>
    </Wrapper>
  );
}
