import { Oval } from "react-loader-spinner";
import styled from "styled-components";

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 100000;
  position: fixed;
  left: 0%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    font-size: 1.1rem;
    margin-top: 15px;
    text-align: center;
  }
  .center_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export default function FullPageLoader({text} : any){
  return (
    <Overlay>
      <div className="center_div">
        <Oval
          height={100}
          width={100}
          color="#fff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff7d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <p>{text}</p>
      </div>
    </Overlay>
  );
};
