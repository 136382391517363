import { IconNav, MainNav } from './internals/types';
import { handleView } from './util/helper';

export const nav: string[] = ['Größe', 'Aussehen', 'Matratze', 'Upgrades', 'Dein Bett'];

export const mainNav: MainNav[] = [
  {
    title: 'Konfigurator'
  },
  {
    title: 'Stoffmuster bestellen'
  },
  {
    title: 'ûber'
  }
];

export const iconNav: IconNav[] = [
  {
    alt: 'contact',
    image: '/images/icons/beraten.png',
    hadleClick: () => handleView('contact')
  },
  {
    alt: 'restart icon',
    image: '/images/icons/restart.png',
    hadleClick: () => handleView('restart')
  },
  {
    alt: 'share',
    image: '/images/icons/teilen.png',
    hadleClick: () => {}
  }
];

export const subMenus = ['Kopfteil', 'Farbe', 'Füße', 'Matratze', 'Topper'];

export const sections: Record<string, any> = {
  grobe: {
    briete: [90, 100, 120, 140, 160, 180, 200],
    lange: [200, 210, 220]
  },

  aussehen: {
    subMenu: ['Kopfteil', 'Farbe', 'Füße'],
    tiefe12: [
      {
        value: 'headboard3',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 3.png'
      },
      {
        value: 'headboard9',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 9.png'
      },
      {
        value: 'headboard6',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 6.png'
      },
      {
        value: 'headboard5',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 5.png'
      },
      {
        value: 'headboard4',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 4.png'
      },
      {
        value: 'headboard7',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 7.png'
      },

      {
        value: 'headboard2',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 2.png'
      }
    ],
    tiefe15: [
      {
        value: 'headboard1',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 1.png'
      },
      {
        value: 'headboard8',
        image: '/images/headboards/200CM_FINE WEAVE_HEADBOARD 8.png'
      }
    ],
    FeinerStoff: [
      {
        value: 'black',
        image: '/images/fabrics/fineWeave/black.png'
      },
      {
        value: 'dark_grey',
        image: '/images/fabrics/fineWeave/dark-grey.png'
      },
      {
        value: 'light_grey',
        image: '/images/fabrics/fineWeave/light-grey.png'
      },
      {
        value: 'sky',
        image: '/images/fabrics/fineWeave/sky.png'
      },
      {
        value: 'emerald',
        image: '/images/fabrics/fineWeave/emerald.png'
      },
      {
        value: 'ice',
        image: '/images/fabrics/fineWeave/ice.png'
      },
      {
        value: 'mud',
        image: '/images/fabrics/fineWeave/mud.png'
      },
      {
        value: 'yellow',
        image: '/images/fabrics/fineWeave/yellow.png'
      },
      {
        value: 'salmon',
        image: '/images/fabrics/fineWeave/salom.png'
      }
    ],
    GroberStoff: [
      {
        value: 'black',
        image: '/images/fabrics/roughWeave/black.png'
      },
      {
        value: 'dark_grey',
        image: '/images/fabrics/roughWeave/dark-grey.png'
      },
      {
        value: 'blue',
        image: '/images/fabrics/roughWeave/blue.png'
      },
      {
        value: 'mixed_brown',
        image: '/images/fabrics/roughWeave/mixed-brown.png'
      },
      {
        value: 'camel',
        image: '/images/fabrics/roughWeave/camel.png'
      },
      {
        value: 'mostaza',
        image: '/images/fabrics/roughWeave/mostaza.png'
      },
      {
        value: 'sand',
        image: '/images/fabrics/roughWeave/sand.png'
      },
      {
        value: 'beige',
        image: '/images/fabrics/roughWeave/beige.png'
      },
      {
        value: 'magenta',
        image: '/images/fabrics/roughWeave/magenta.png'
      },
      {
        value: 'white',
        image: '/images/fabrics/roughWeave/white.png'
      },
      {
        value: 'light_grey',
        image: '/images/fabrics/roughWeave/light-grey.png'
      }
    ],
    Leder: [
      {
        value: 'white',
        image: '/images/fabrics/leather/white.png'
      },
      {
        value: 'camel',
        image: '/images/fabrics/leather/camel.png'
      },
      {
        value: 'red',
        image: '/images/fabrics/leather/red.png'
      },
      {
        value: 'toffee',
        image: '/images/fabrics/leather/toffee.png'
      },
      {
        value: 'trufa',
        image: '/images/fabrics/leather/trufa.png'
      },
      {
        value: 'tabac',
        image: '/images/fabrics/leather/tabac.png'
      },
      {
        value: 'mouse_grey',
        image: '/images/fabrics/leather/mouse-grey.png'
      },
      {
        value: 'grey',
        image: '/images/fabrics/leather/grey.png'
      },
      {
        value: 'black',
        image: '/images/fabrics/leather/black.png'
      }
    ],
    Samt: [
      {
        value: 'moss_green',
        image: '/images/fabrics/velvet/moss-green.png'
      },
      {
        value: 'beige',
        image: '/images/fabrics/velvet/beige.png'
      },
      {
        value: 'bordeaux',
        image: '/images/fabrics/velvet/bordeaux.png'
      },
      {
        value: 'eggplant',
        image: '/images/fabrics/velvet/eggplant.png'
      },
      {
        value: 'rose',
        image: '/images/fabrics/velvet/rose.png'
      },
      {
        value: 'lilac',
        image: '/images/fabrics/velvet/lilac.png'
      },
      {
        value: 'light_grey',
        image: '/images/fabrics/velvet/light-grey.png'
      },
      {
        value: 'mint',
        image: '/images/fabrics/velvet/mint.png'
      },
      {
        value: 'ocean',
        image: '/images/fabrics/velvet/ocean.png'
      }
    ],
    hohe: [
      {
        value: 'leg3',
        image: '/images/legs/leg3.png',
        options: [
          {
            value: 'steel',
            image: '/images/legs/LEG_METAL.png',
            name: 'Edelstahl',
            backgroundColor: '#f1f1f1'
          }
        ]
      },
      {
        value: 'leg1',
        image: '/images/legs/leg1.png',
        options: [
          {
            value: 'steel',
            image: '/images/legs/LEG_METAL.png',
            name: 'Edelstahl',
            backgroundColor: '#f1f1f1'
          },
          {
            value: 'light_wood',
            image: '/images/legs/LEG_LIGHT-WOOD-MATERIAL.png',
            name: 'Holz',
            backgroundColor: '#DCBF96'
          },
          {
            value: 'dark_wood',
            image: '/images/legs/LEG_DARK-WOOD-MATERIAL.png',
            name: 'Schwarz',
            backgroundColor: '#1B0001'
          }
        ]
      },
      {
        value: 'leg2',
        image: '/images/legs/leg2.png',
        options: [
          {
            value: 'steel',
            image: '/images/legs/LEG_METAL.png',
            name: 'Edelstahl',
            backgroundColor: '#f1f1f1'
          },
          {
            value: 'light_wood',
            image: '/images/legs/LEG_LIGHT-WOOD-MATERIAL.png',
            name: 'Holz',
            backgroundColor: '#DCBF96'
          },
          {
            value: 'dark_wood',
            image: '/images/legs/LEG_DARK-WOOD-MATERIAL.png',
            name: 'Schwarz',
            backgroundColor: '#1B0001'
          }
        ]
      },
      {
        value: 'leg4',
        image: '/images/legs/leg4.png',
        options: [
          {
            value: 'dark_wood',
            image: '/images/legs/LEG_DARK-WOOD-MATERIAL.png',
            name: 'Schwebeoptik · Nur hintere Füße sichtbar'
          }
        ]
      }
    ]
  },

  matratze: {
    subMenu: ['Matratze', 'Topper'],
    optik: [
      {
        name: 'Durchgehend',
        value: 'full',
        image: '/images/full.png'
      },
      {
        name: 'Getrennt',
        value: 'split',
        image: '/images/split.png'
      }
    ],

    matratze: [
      {
        value: 'H2',
        size: 'bis 70kg'
      },
      {
        value: 'H3',
        size: '70kg bis 110kg'
      },
      {
        value: 'H4',
        size: 'ab 110kg'
      }
    ],
    toppers: [
      {
        value: '5cm',
        image: '/images/Topper/5CM.png',
        name: 'Schaum (5 cm)'
      },
      {
        value: '9cm',
        image: '/images/Topper/9CM.png',
        name: 'Latex (9 cm)'
      }
    ]
  },

  stauraum: [
    {
      value: 'side',
      image: '/images/upgrades/Side_Storage.png',
      name: 'Seitlich öffnend'
    },
    {
      value: 'front',
      image: '/images/upgrades/Front_Storage.png',
      name: 'Frontal öffnend'
    }
  ]
};
