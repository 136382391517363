import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';

const NextWrapper = styled.div`
  Button {
    position: relative;
    // z-index: 1;
    width: 100%;
    margin: 25px auto 16px;
    padding-right: 0;
    bottom: 0px;
    img {
      width: 35px;
    }
  }
`;

export interface NextButtonProps {
  label: string;
  handleNext?: (e: any) => void;
  order?: boolean;
  [x: string]: any;
}

const NextButton = ({ label, handleNext, order = true, ...rest }: NextButtonProps) => {
  return (
    <NextWrapper>
      <Button
        label={label}
        transparent
        order={order}
        onClick={handleNext}
        customStyles={{
          fontWeight: '500'
        }}
        {...rest}>
        <img src="/images/icons/nextIcon.svg" alt="next icon" />
      </Button>
    </NextWrapper>
  );
};

export default React.memo(NextButton);
