import classNames from 'classnames';
import { useContext, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ConfigContext } from '../../../../context';
import { nav } from '../../../../data';
import { handleView } from '../../../../util/helper';
import { validateField } from '../../../../util/validation';
import Content from '../../../Contents/Content';
import Direction from '../../../Directions';
import InputButton from '../../../InputButton/InputButton';
import styles from './Topper.module.scss';

function Matratze({ data }: any) {
  const [topper, setTopper] = useState('');

  const { form, setForm, setStep, setErrors, errors } = useContext(ConfigContext);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setForm({
      ...form,
      topper: {
        ...form.topper,
        [name]: value
      }
    });
  };

  const handleNext = () => {
    const errors = validateField(form, ['topper']);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        ...step,
        navStep: step.navStep + 1,
        state: nav[step.navStep + 1]
      }));
    }
  };

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      subNavStep: step.subNavStep - 1
    }));
  };

  return (
    <section className={styles.section} id="aside">
      <div className={styles.header}>
        <h2>Topper</h2>
        <p>
          Den Mollybett Topper gibt es in zwei Ausführungen. Je höher desto wiecher das Liegegefühl.
        </p>
      </div>

      <div className={styles.main}>
        <p
          className={classNames(styles.subTitle, {
            error: errors['topper']
          })}>
          Ausführung
        </p>
        <div className={styles.button}>
          {data.toppers.map((topper: any, index: number) => (
            <div
              key={index}
              style={
                {
                  // width: topper.value === '9cm' ? '90%' : '100%'
                }
              }>
              <p className={styles.subHeader}>{topper.name}</p>
              <InputButton
                item={topper.value}
                image
                name="width"
                checked={topper.value === form.topper.width}
                handleChange={handleChange}>
                <img
                  src={topper.image}
                  alt="bed-style"
                  className={styles.buttonImage}
                  style={{
                    height: '86px',
                    objectFit: 'contain'
                  }}
                />
              </InputButton>
              <div
                className={styles.infoSection}
                onClick={() => {
                  setTopper(topper.value);
                  handleView('topper');
                }}
                style={{
                  marginTop: '7px'
                }}>
                <AiOutlineInfoCircle fontSize="20px" />
                <h6>Information</h6>
              </div>
            </div>
          ))}
        </div>
        <Content id="topper" topper={topper} />
      </div>

      <Direction
        backButton={{
          label: '',
          handleBack,
          imgStyles: {
            marginLeft: 0
          }
        }}
        nextButton={{ label: 'weiter', handleNext }}
      />
    </section>
  );
}

export default Matratze;
