import { useEffect, useState } from 'react';
import styles from './Content.module.scss';

// H2 from 0 to 21.5
// H3 from 21.5 to 27.5
// H4 from 27.5 to infinite
const IBM = ({ children }: any) => {
  const [values, setValues] = useState({
    height: '',
    weight: ''
  });
  const [weight, setWeight] = useState(0);

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setValues({
      ...values,
      [name]: value
    });
  };

  useEffect(() => {
    if (values.height && values.weight) {
      const height = (parseFloat(values.height) * parseFloat(values.height)) / 10000;
      let weight = parseFloat(values.weight) / height;

      weight = parseFloat(weight.toFixed(1));

      setWeight(weight);
    }
  }, [values]);

  const getBmiEqu = (weight: number): string | null => {
    if (!values.height || !values.weight) {
      return '';
    }
    if (weight >= 0 && weight <= 21.5) {
      return 'H2';
    } else if (weight > 21.5 && weight <= 27.5) {
      return 'H3';
    } else if (weight > 27.5 && weight <= Infinity) {
      return 'H4';
    } else return '-';
  };

  return (
    <>
      {children}

      <div className={styles.ibm_errechne}>
        <h4>Errechne deinen Härtegrad</h4>
        <p>Gewicht</p>
        <div>
          <input onChange={handleChange} name="weight" value={values.weight} />
          <span>kg</span>
        </div>
        <p>Größe</p>
        <div>
          <input onChange={handleChange} name="height" value={values.height} />
          <span>cm</span>
        </div>

        <p>
          Empfohlener Härtegrad:<span>{getBmiEqu(weight)}</span>
        </p>
      </div>

      <div className={styles.ibm_weiter}>
        <h5>Weitere Informationen</h5>
        <p>
          Zum empfohlenen Härtegrad, liegt es auch an dir, wie weich bzw. hart du liegen möchtest.
          Wenn du es bevorzugst weicher zu liegen, kannst du ohne weiteres auch einen niedrigeren
          Härtegrad auswählen - das Gleiche gilt für härteres Liegen. Alternativ kannst du auch im
          nächsten Schritt den höheren Topper wählen. Dieser sorgt auch für ein weicheres
          Liegegefühl.
        </p>
      </div>
    </>
  );
};

export default IBM
