import styled from 'styled-components';
import BackButton, { BackButtonProps } from './BackButton';
import NextButton, { NextButtonProps } from './NextButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface DirectionsProps {
  backButton: BackButtonProps;
  nextButton: NextButtonProps;
}

const Direction = ({ backButton, nextButton }: DirectionsProps) => {
  return (
    <Wrapper>
      <BackButton {...backButton} />
      <NextButton {...nextButton} />
    </Wrapper>
  );
};

export default Direction;
