import styled from 'styled-components';
import Button from '../Button/Button';

interface IPopupModal {
  onSelect: (value: boolean) => void;
}
const Modal = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 250, 250, 0.8);
  z-index: 2;

  @include smd {
    position: fixed;
    top: 0;
  }

  @include md {
    padding: 0px 45px;
  }
`;

const ModalContent = styled.div`
  width: 60%;
  position: relative;
  background-color: rgba(255, 255, 255);
  padding: 10px 15px;
  text-align: center;

  @media (max-width: 768px) {
    width: 85%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 60%;
  }

  h5 {
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.75rem;
    margin-bottom: 10px;
  }
  // p:first-child{
  //   margin-bottom: 15px;
  // }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    & > div:first-child {
      margin-right: 15px;
    }
  }
`;

const PopupModal = ({ onSelect }: IPopupModal) => {
  return (
    <Modal>
      <ModalContent>
        <h5>Information</h5>
        <p>Deine Auswahl kann nicht Information leider nicht miteinander kombiniert werden.</p>
        <p>Möchtest du fortfahren?</p>

        <div className="buttons">
          <Button
            label="JA"
            onClick={() => {
              onSelect(true);
            }}
            transparent
            customStyles={{
              borderRadius: '3px',
              padding: '1px 16px'
            }}
          />

          <Button
            label="NEIN"
            onClick={() => {
              onSelect(false);
            }}
            transparent
            customStyles={{
              borderRadius: '3px',
              padding: '1px 16px'
            }}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default PopupModal;
