import React, { useContext } from 'react';
import styles from './Matratzes.module.scss';
import NavAside from '../../NavAside/NavAside';
import { sections } from '../../../data';
import Matratze from './Matratze/Matratze';
import Topper from './Topper/Topper';
import { ConfigContext } from '../../../context';

function Matratzes({ data }: any) {
  const { step } = useContext(ConfigContext);

  return (
    <section className={styles.section}>
      <NavAside data={data.subMenu} />
      {step.subNavStep === 3 && <Matratze data={sections.matratze} />}
      {step.subNavStep === 4 && <Topper data={sections.matratze} />}
    </section>
  );
}

export default Matratzes;
