import React from 'react';
import Nav from '../Nav/Nav';
import styles from './Header.module.scss';
import { mainNav } from '../../data';
import { MainNav } from '../../internals/types';

function Header() {
  return (
    <div className={styles.container}>
      <div className={styles.leftHeader}>
        <img src="images/newlogo.svg" alt="logo" className={styles.logo} />
        {/* {mainNav.map((nav: MainNav, index: number) => (
          <p key={index}>{nav.title}</p>
        ))} */}
      </div>
      <Nav />
    </div>
  );
}

export default Header;
