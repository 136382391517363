import React from 'react'
import styles from './Aside.module.scss'
import Footer from '../Footer/Footer'


function Aside({children}: any) {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
      {children}
      </div>
     <Footer />
    </div>
  )
}

export default Aside