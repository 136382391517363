import React, { useContext } from 'react';
import { useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import styles from './Nav.module.scss';
import { TfiClose } from 'react-icons/tfi';
import { BsTwitter } from 'react-icons/bs';
import { FaTiktok, FaFacebookF } from 'react-icons/fa';
import { AiOutlineInstagram, AiOutlineMenu } from 'react-icons/ai';
import { ConfigContext } from '../../context';
import { iconNav, nav as navMenu } from '../../data';
import { handleView } from '../../util/helper';
import { IconNav } from '../../internals/types';

export default function Nav() {
  const { step, setStep, currentState, setIsStoffmuster } = useContext(ConfigContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = (index: number) => {
    const state = navMenu[index];

    // TODO: Find a better way to track subNavSteps rather than using the actual nav steps
    let subNavStep = state === 'Matratze' ? 3 : 0;

    if (index <= currentState.currentNavState) {
      setStep((step: any) => ({
        navStep: index,
        state,
        subNavStep
      }));
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <>
      <div className={styles.hamburger}>
        <AiOutlineMenu
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{
            width: '30px'
          }}
        />
      </div>
      <div className={styles.iconNavList}>
        <AiOutlineMenu
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{
            width: '30px',
            cursor: 'pointer'
          }}
        />
      </div>

      <div className={`${styles.nav_container} ${isMenuOpen ? styles.nav_active : ''}`}>
        <div className={styles.nav_close}>
          <TfiClose onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </div>
        <div>
          <h2>Konfigurator</h2>
          <div>
            <ul className={styles.mobile_navList}>
              {navMenu?.map((item: string, index: any) => (
                <li
                  className={index <= step.navStep ? styles.active : styles.mobile_navItems}
                  key={index}
                  onClick={() => handleMenu(index)}>
                  {item}
                </li>
              ))}
              <li
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  setIsStoffmuster((state: boolean) => !state);
                  handleView('stoffmuster');
                }}>
                Stoffmuster bestellen
              </li>
            </ul>
            {/* <ul className={styles.mobile_navList}>
                {data?.map((item: string, index: any) => (
                  <li
                    className={index <= step.navStep ? styles.active : styles.mobile_navItems}
                    key={index}
                    onClick={() => handleMenu(index)}>
                    {item}
                  </li>
                ))}
                <li
                  onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                    setIsStoffmuster((state: boolean) => !state);
                    handleView('stoffmuster');
                  }}>
                  Stoffmuster bestellen
                </li>
              </ul> */}
          </div>

          {/* <div>
              <ul className={styles.mobile_navList}>
                <li>Log In</li>
                <li>Konfiguration speichern</li>
                <li
                  onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                    handleView('contact');
                  }}>
                  Beraten lassen
                </li>
                <li
                  onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                    handleView('restart');
                  }}>
                  Neustart
                </li>
                <li>Stoffmuster bestellen</li>
              </ul>
            </div> */}

          <div className={styles.mobile_socialIcons}>
            <ul className={styles.mobile_socialIconsList}>
              <FaFacebookF />
              <AiOutlineInstagram />
              <BsTwitter />
              <FaTiktok />
            </ul>
            <p>Impressum · Datenschutz</p>
          </div>
        </div>
      </div>
    </>
  );
}
