import classNames from 'classnames';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../context';
import { validateField } from '../../../../util/validation';
import Content from '../../../Contents/Content';
import Direction from '../../../Directions';
import InputButton from '../../../InputButton/InputButton';
import styles from './Farbe.module.scss';

function Farbe({ data }: any) {
  const {
    form,
    setForm,
    fields,
    errors,
    setErrors,
    setStep,
    setIsImageLoaded1,
    setIsImageLoaded4,
    setIsImageLoaded5,
    defaultValues
  } = useContext(ConfigContext);

  const handleChange = (e: any, material: string) => {
    const { value, name } = e.target;

    if (defaultValues.farbe.color !== value && defaultValues.farbe.material !== material) {
      setIsImageLoaded1(false);
      setIsImageLoaded4(false);
      setIsImageLoaded5(false);
    }

    setForm({
      ...form,
      [name]: {
        color: value,
        material
      }
    });
  };

  const handleNext = () => {
    const errors = validateField(form, fields['farbe']);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        ...step,
        subNavStep: step.subNavStep + 1
      }));
    }
  };

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      subNavStep: step.subNavStep - 1
    }));
  };

  return (
    <section className={styles.section} id="aside">
      <Content id="stoffmuster" data={data} />
      <div className={styles.header}>
        <h2>Farbe</h2>
        <p>Personalisiere dein Mollybett und wähle aus unterschiedlichen Stoffen und Farben.</p>
      </div>

      <div className={`${styles.main} ${styles['main-first']}`}>
        <p className={classNames({ error: errors['farbe.color'] })}>Feiner Stoff</p>
        <div className={styles.button}>
          {data.FeinerStoff?.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <InputButton
                item={item.value}
                image
                circular
                checked={item.value === form.farbe.color && form.farbe.material === 'fineWeave'}
                name="farbe"
                handleChange={(e) => handleChange(e, 'fineWeave')}>
                <img src={item.image} alt="bed-style" />
              </InputButton>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <p>Grober Stoff</p>
        <div className={styles.button}>
          {data.GroberStoff?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                circular
                checked={item.value === form.farbe.color && form.farbe.material === 'roughWeave'}
                handleChange={(e) => handleChange(e, 'roughWeave')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <p>Leder</p>
        <div className={styles.button}>
          {data.Leder?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                circular
                checked={item.value === form.farbe.color && form.farbe.material === 'leather'}
                handleChange={(e) => handleChange(e, 'leather')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.main}>
        <p>Samt</p>
        <div className={styles.button}>
          {data.Samt?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                name="farbe"
                circular
                checked={item.value === form.farbe.color && form.farbe.material === 'velvet'}
                handleChange={(e) => handleChange(e, 'velvet')}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <Direction
        backButton={{ label: '', handleBack }}
        nextButton={{ label: 'weiter', handleNext }}
      />
    </section>
  );
}

export default Farbe;
