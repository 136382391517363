import classNames from 'classnames';
import React, { useContext, useRef } from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { ConfigContext } from '../../../../context';
import { nav } from '../../../../data';
import { validateField } from '../../../../util/validation';
import Direction from '../../../Directions';
import InputButton from '../../../InputButton/InputButton';
import styles from './Kopfteil.module.scss';

function Kopfteil({ data }: any) {
  const { form, setForm, fields, errors, setErrors, setStep } = useContext(ConfigContext);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setForm({
      ...form,
      [name]: {
        headboard: value,
        width: value === 'headboard1' || value === 'headboard8' ? '15cm' : '9cm'
      }
    });
  };

  const handleNext = () => {
    const errors = validateField(form, fields['kopfteil']);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        ...step,
        subNavStep: step.subNavStep + 1
      }));
    }
  };

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      navStep: step.navStep - 1,
      state: nav[step.navStep - 1]
    }));
  };

  return (
    <section className={styles.section} id="aside" ref={ref}>
      <div className={styles.header}>
        <h2>Kopfteil</h2>
        <p>
          Das Kopfteil gibt deinem Bett den individuellen Look. Wähle aus zwei unterschiedlichen
          Breiten aus.
        </p>
      </div>

      <div className={styles.main}>
        <p className={classNames(styles.mainTitle, { error: errors['kopfteil.headboard'] })}>
          12 cm (Tiefe)
        </p>
        <div className={styles.button}>
          {data.tiefe12?.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <InputButton
                item={item.value}
                image
                checked={item.value === form.kopfteil.headboard}
                name="kopfteil"
                handleChange={handleChange}
                customStyles={{
                  padding: 0
                }}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </React.Fragment>
          ))}
          <div
            className={styles.arrow_pointer}
            onClick={() => {
              if (ref.current) {
                ref.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                  inline: 'nearest'
                });
              }
            }}>
            <div>
              <p>weitere Kopfteile</p>
              <BsArrowDown />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.main}>
        <p className={classNames(styles.mainTitle, { error: errors['kopfteil.headboard'] })}>
          15 cm (Tiefe)
        </p>
        <div className={styles.button}>
          {data.tiefe15?.map((item: any, index: any) => (
            <div key={index}>
              <InputButton
                item={item.value}
                image
                checked={item.value === form.kopfteil.headboard}
                name="kopfteil"
                handleChange={handleChange}
                customStyles={{
                  padding: 0
                }}>
                <img src={item.image} alt="bed-style" className={styles.buttonImage} />
              </InputButton>
            </div>
          ))}
        </div>
      </div>

      <Direction
        backButton={{
          label: '',
          handleBack,
          imgStyles: {
            marginLeft: 0
          }
        }}
        nextButton={{ label: 'weiter', handleNext }}
      />
    </section>
  );
}

export default Kopfteil;
