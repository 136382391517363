import { IForm } from '../types';

export const findBedImages = (defaultValues: IForm ,properties: any) => {
  const {
    fube,
    kopfteil,
    farbe,
    matratze,
    topper,
    bedType,
    storageType
  } = defaultValues;


  //@ts-ignore
  let position1 = properties.headboards[kopfteil.headboard][farbe.material].find(
    (_el: any) => _el.type === farbe.color
  ).image;

  let position2 = null;

  if (storageType) {
    position2 = properties.storage[storageType][farbe.material as string].find(
      (_el: any) => _el.type === farbe.color
    ).image;
  }

  let position3 = properties.legs[fube.category as string].find(
    (_el: any) => _el.type === fube.type
  ).image;

  let position4 = properties.base[bedType][farbe.material as string].find(
    (_el: any) => _el.type === farbe.color
  ).image;

  let position5 = properties.matratze[bedType][matratze.type as string][
    farbe.material as string
  ].find((_el: any) => _el.type === farbe.color).image;

  let position6 = properties.topper[bedType][topper.type].find(
    (_el: any) => _el.width === topper.width
  ).image;

  return { position1, position2, position3, position4, position5, position6 };
};
