import styles from "./Content.module.scss";

export default function Contact ({ children }: any){


  return (
    <div className={styles.infoSlide} id="contact">
      {children}

      <div className={styles.ibm_errechne}>
        <h4>Persönliche Beratung</h4>
        <p className="text--sm">
          Wir stehen dir für alle Fragen rund um Ergonomie, Aussehen und
          sonstigen Anliegen zur Verfügung.
        </p>

        <div className={styles.contact_info}>
          <img src="/images/beraten.jpeg" alt="user"/>
          <div>
            <h3>T. Nowotny</h3>
            <p>0721 494570</p>
          </div>
        </div>
      </div>
    </div>
  );
};
