import classNames from 'classnames';
import { useContext } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ConfigContext } from '../../../../context';
import { nav } from '../../../../data';
import { handleView } from '../../../../util/helper';
import { validateField } from '../../../../util/validation';
import Content from '../../../Contents/Content';
import Direction from '../../../Directions';
import InputButton from '../../../InputButton/InputButton';
import styles from './Matratze.module.scss';

function Matratze({ data }: any) {
  const { form, setForm, fields, errors, setErrors, setStep } = useContext(ConfigContext);

  // const [pendingValue, setPendingValue] = useState('');

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    // if (name === "type") setIsImageLoaded5(false);

    let bedType = form.bedType;

    if (name === 'type') {
      bedType = form.bedType === 'electric' ? 'standard' : form.bedType;
      // bedType = form.bedType === 'electric' && pendingValue === 'full' ? 'standard' : form.bedType;
    }

    setForm({
      ...form,
      matratze: {
        ...form.matratze,
        [name]: value
      },
      bedType,
      topper: {
        ...form.topper,
        type: 'full'
      }
    });
  };

  const isMatratze2 = form.briete && form.briete > 120;

  const handleNext = () => {
    const validationFields = [...fields['matratze']];

    if (isMatratze2) validationFields.push('matratze.type', 'matratze.matratzeWeight2');
    const errors = validateField(form, validationFields);

    setErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      setStep((step: any) => ({
        ...step,
        subNavStep: step.subNavStep + 1
      }));
    }
  };

  const handleBack = () => {
    setStep((step: any) => ({
      navStep: step.navStep - 1,
      state: nav[step.navStep - 1],
      subNavStep: step.subNavStep - 1
    }));
  };
  return (
    <section className={styles.section} id="aside">
      <div className={styles.header}>
        <h2>Matratze</h2>
        <p className={styles.infoText} onClick={() => handleView('matratze')}>
          <span>Taschenfederkern</span>
          <AiOutlineInfoCircle fontSize="18px" />
        </p>
        <p>Wähle den Härtegrad deiner Mollybett Taschenfederkernmatratze.</p>
      </div>

      <Content id="matratze" />

      <div className={styles.main}>
        <p className={styles.subTitle}>Härtegrad</p>
        <div className={styles.button}>
          <div>
            <p
              className={classNames(styles.subHeader, {
                error: errors['matratze.matratzeWeight1']
              })}>
              Matratze {isMatratze2 ? 1 : null}
            </p>
            {data.matratze?.map((item: any, index: any) => (
              <div className={styles.sizes} key={index}>
                <InputButton
                  item={item.value}
                  image
                  name="matratzeWeight1"
                  checked={item.value === form.matratze.matratzeWeight1}
                  handleChange={handleChange}
                  key={index}
                  isBlackText>
                  <div className={styles.buttonText}>
                    <p>{item.value}</p>
                    <span>{item.size}</span>
                  </div>
                </InputButton>
              </div>
            ))}
          </div>
          {isMatratze2 && (
            <div>
              <p
                className={classNames(styles.subHeader, {
                  error: errors['matratze.matratzeWeight2']
                })}>
                Matratze 2
              </p>
              {data.matratze?.map((item: any, index: any) => (
                <div className={styles.sizes} key={index}>
                  <InputButton
                    item={item.value}
                    image
                    name="matratzeWeight2"
                    checked={item.value === form.matratze.matratzeWeight2}
                    handleChange={handleChange}
                    key={index}
                    isBlackText>
                    <div className={styles.buttonText}>
                      <p>{item.value}</p>
                      <span>{item.size}</span>
                    </div>
                  </InputButton>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.infoSection} onClick={() => handleView('ibm')}>
          <AiOutlineInfoCircle fontSize="20px" />
          <h6>
            Alternativ :<span>Ermittle den passenden Härtegrad anhand deines BMI</span>
          </h6>
        </div>
        <Content id="ibm" />
      </div>

      {isMatratze2 && (
        <div className={`${styles.main} ${styles['main--subMain']}`}>
          <p className={styles.subTitle}>Optik</p>
          <div
            className={styles.button}
            style={{
              marginBottom: '10px'
            }}>
            {data.optik?.map((item: any, index: number) => (
              <div className={styles.inputBlock} key={index}>
                <p
                  className={classNames({
                    error: errors['matratze.type']
                  })}>
                  {item.name}
                </p>
                <InputButton
                  item={item.value}
                  image
                  name="type"
                  checked={item.value === form.matratze.type}
                  handleChange={handleChange}
                  key={index}>
                  <img src={item.image} alt="bed-style" className={styles.buttonImage} />
                </InputButton>
              </div>
            ))}
          </div>
          <div className={styles.infoSection}>
            <AiOutlineInfoCircle fontSize="20px" />
            <h6>
              Bei einer optisch durchgehenden Matratze handelt es sich dennoch um 2 einzelne
              Matratzen.
            </h6>
          </div>
        </div>
      )}

      <Direction
        backButton={{
          label: '',
          handleBack,
          imgStyles: {
            marginLeft: 0
          }
        }}
        nextButton={{ label: 'weiter', handleNext }}
      />
    </section>
  );
}

export default Matratze;
