import { useContext, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ConfigContext } from '../../../context';
import { nav } from '../../../data';
import Direction from '../../Directions';
import InputButton from '../../InputButton/InputButton';
import PopupModal from '../../PopupModal/PopupModal';
import styles from './Upgrades.module.scss';
import { IForm } from '../../../types';

// TODO : Refactor this entire module
function Upgrade({ data }: any) {
  const { setStep, form, setForm } = useContext(ConfigContext);

  const [modal, setModal] = useState(false);
  const [pendingValue, setPendingValue] = useState({
    bedType: 'standard',
    storageType: null
  });

  const [previousValue, setPreviousValues] = useState({
    fube: {
      category: '',
      type: ''
    },
    matratzeType: ''
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    //Check if you are switching between same type or you are unselcting.

    const topperType = name === 'electric' && form.briete > 120 ? 'split' : 'full';
    const matratzeType = name === 'electric' && form.briete > 120 ? 'split' : form.matratze.type;

    const legCategory =
      name === 'electric' && form.fube.category === 'leg4'
        ? 'leg2'
        : previousValue.fube.category ?? form.fube.category;
    if (name === form.bedType) {
      // The first is to unselect the same value

      if ((name === 'storage' && form.storageType === value) || name === 'electric') {
        setForm((prevForm: any) => {
          return {
            ...prevForm,
            bedType: 'standard',
            storageType: null,
            topper: {
              ...prevForm.topper,
              type: 'full'
            },
            fube: previousValue.fube.category ? previousValue.fube : prevForm.fube,
            matratze: {
              ...prevForm.matratze,
              type: previousValue.matratzeType || form.matratze.type
            }
          };
        });
      } else {
        // Switch between storage types or setting elctric type
        setForm({
          ...form,
          bedType: name,
          storageType: name === 'storage' ? value : null,
          fube: {
            ...form.fube,
            category: legCategory
          },
          topper: {
            ...form.topper,
            type: topperType
          },
          matratze: {
            ...form.matratze,
            type: matratzeType
          }
        });
      }
    } else if (form.bedType === 'standard') {
      setPreviousValues({
        fube: form.fube,
        matratzeType: form.matratze.type
      });

      setForm((prevForm: IForm) => {
        return {
          ...prevForm,
          bedType: name,
          storageType: name === 'storage' ? value : null,
          fube: {
            ...form.fube,
            category: legCategory
          },
          topper: {
            ...prevForm.topper,
            type: topperType
          },
          matratze: {
            ...prevForm.matratze,
            type: matratzeType
          }
        };
      });
    } else {
      // Set current value when you want to change the bed type from storage to electric or vice versa
      setPendingValue({
        bedType: name,
        storageType: name === 'storage' ? value : null
      });
      setModal(true);
    }
  };

  const onSelect = (value: boolean) => {
    if (value) {
      const { bedType, storageType } = pendingValue;
      const splitType = bedType === 'electric' && form.briete > 120 ? 'split' : 'full';
      const matratzeType =
        bedType === 'electric' && form.briete > 120
          ? 'split'
          : previousValue.matratzeType || form.matratze.type;

      const legCategory =
        bedType === 'electric' && form.fube.category === 'leg4'
          ? 'leg2'
          : previousValue.fube.category ?? form.fube.category;

      setForm({
        ...form,
        bedType,
        storageType,
        fube: {
          ...form.fube,
          category: legCategory
        },
        topper: {
          ...form.topper,
          type: splitType
        },
        matratze: {
          ...form.matratze,
          type: matratzeType
        }
      });
    }

    setModal(false);
  };

  const handleNext = () => {
    setStep((step: any) => ({
      ...step,
      navStep: step.navStep + 1,
      state: nav[step.navStep + 1]
    }));
  };

  const handleBack = () => {
    setStep((step: any) => ({
      ...step,
      navStep: step.navStep - 1,
      state: nav[step.navStep - 1]
    }));
  };

  return (
    <div>
      {modal && <PopupModal onSelect={onSelect} />}

      <section className={styles.section}>
        <div className={styles.header}>
          <h2>Upgrades</h2>
        </div>

        <div className={styles.main}>
          <p>Elektrisch verstellbar</p>
          <div className={styles.button}>
            <div>
              <InputButton
                item="electric"
                name="electric"
                image="true"
                type="checkbox"
                handleChange={handleChange}
                checked={'electric' === form.bedType}>
                <img
                  src="/images/upgrades/Electric_Motor.png"
                  alt="bed-style"
                  className={styles.buttonImage}
                />
              </InputButton>

              <div
                className={styles.infoSection}
                onClick={() => {
                  // handleView("topper");
                }}
                style={{
                  marginTop: '7px'
                }}>
                <AiOutlineInfoCircle fontSize="20px" />
                <h6>Information</h6>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.main} ${styles['main--subMain']}`}>
          <p>Stauraum</p>
          <div className={styles.button}>
            {data.map((item: any, index: any) => (
              <div key={index}>
                <p className={styles.subHeader}>{item.name}</p>
                <InputButton
                  item={item.value}
                  image="true"
                  handleChange={handleChange}
                  name="storage"
                  key={index}
                  checked={item.value === form.storageType}
                  type="checkbox">
                  <img src={item.image} alt="bed-style" className={styles.buttonImage} />
                </InputButton>

                <div
                  className={styles.infoSection}
                  onClick={() => {
                    // handleView("topper");
                  }}
                  style={{
                    marginTop: '7px'
                  }}>
                  <AiOutlineInfoCircle fontSize="20px" />
                  <h6>Information</h6>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Direction
          backButton={{
            label: '',
            handleBack,
            imgStyles: {
              marginLeft: 0
            }
          }}
          nextButton={{ label: 'Fertigstellen', handleNext }}
        />
      </section>
    </div>
  );
}

export default Upgrade;
