import React, { useContext } from 'react';
import { ConfigContext } from '../../context';
import { formatCurrency } from '../../util/helper';
import styles from './Footer.module.scss';

function Footer() {
  const { amount, IsStoffmuster, form, stoffmusters } = useContext(ConfigContext);

  return (
    <div className={styles.container}>
      {IsStoffmuster ? (
        <div className={`${styles.info}  ${styles.stoff}`}>
          <h4>Ausgewählt:</h4>
          <p>
            <span>Stoffmuster kaufen</span>
            <span>{stoffmusters.length} von 5</span>
          </p>
        </div>
      ) : (
        <div className={styles.info}>
          <h4>Preis:</h4>
          <p>
            <span>
              {form?.briete ? `${formatCurrency(amount)}` : `ab ${formatCurrency(179800)}`}
            </span>
            <span>inkl. Mwst & zzgl. Versand</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default Footer;
