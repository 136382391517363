import styles from './Content.module.scss';

export default function Mattrez({ children }: any) {
  return (
    <div className={styles.infoSlide} id="matratze">
      {children}

      <div className={styles.ibm_errechne}>
        <h4>Hochwertige Taschenfedern</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor
          in- vidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          accu- sam et justo duo dolores et ea rebum.
        </p>
        {/* <img
          alt="mattrez"
          src="images/matteresinfo.png"
          style={{
            width: '100%',
            marginTop: '30px'
          }}
        /> */}
      </div>

      <div className={styles.ibm_errechne}>
        <h4>Optimale Gewichtsverteilung</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadips- cing elitr, sed diam nonumy eirmod tempor
          in- Die Mollybed Taschenfederkernmatratze wurde gemeinsam mit vidunt ut labore et dolore
          magna aliquyam Schlafexperten für dich entwickelt. erat, sed diam voluptua. At vero eos et
          accu- sam et justo duo dolores et ea rebum.
        </p>
      </div>
    </div>
  );
}
